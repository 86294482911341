import { Auth, RefreshScheme } from '@nuxtjs/auth-next/dist/runtime'
import { LocalSchemeOptions } from '@nuxtjs/auth-next/dist'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import { HTTPResponse } from '@nuxtjs/auth-next'
import { Context } from '@nuxt/types'
import { Store } from 'vuex'
import {
  GetDefaultWebPreferences,
  User,
  UserMessages,
  WebPreferences,
} from '~/models/users/user'
import { Login } from '~/models/users/login'
import {
  ApiAccessCookie,
  ApiRefreshCookie,
  DeleteCookie,
} from '~/assets/ts/utils/cookies'
import { Dashboard } from '~/models/dashboard'
import { SimpleHash } from '~/assets/ts/utils/misc'
import { LocalState } from '~/store/local'
import { DisplayUserApi } from '~/models/usercomment'

export class Users {
  $auth: Auth
  $axios: NuxtAxiosInstance
  $context: Context
  $store: Store<LocalState>
  dashboard: Dashboard
  cookieMaxAge?: number

  constructor(settings: any) {
    this.$context = settings.$context as Context
    this.$auth = settings.$context.$auth as Auth
    this.$axios = settings.$context.$axios as NuxtAxiosInstance
    this.$store = settings.$context.store as Store<LocalState>
    this.dashboard = new Dashboard(this.$context)
    if (this.$auth.options.cookie) {
      this.cookieMaxAge = this.$auth.options.cookie.options.maxAge
    }
  }

  get cacheUserToken(): string {
    const strategy = this.$auth.strategy as RefreshScheme
    const token = strategy.refreshToken.get().toString()
    return SimpleHash(token)
  }

  /** Casts the currently logged in user from $auth as a User */
  get user(): User | undefined {
    if (!this.loggedIn) return undefined
    return new User(this.$auth.user)
  }

  /** Alias for $auth.loggedIn */
  get loggedIn(): boolean {
    return this.$auth.loggedIn
  }

  /** Alias for $auth.logout */
  async logOut(): Promise<void> {
    deleteSharedAuthCookies()
    await this.$auth.logout()
  }

  async logIn(login: Login): Promise<HTTPResponse | void> {
    const options = this.$auth.strategies.local.options as LocalSchemeOptions
    options.endpoints.login.url = `/site/users/${login.username}/login`
    if (this.$auth.options.cookie) {
      this.$auth.options.cookie.options.maxAge = login.rememberMe
        ? this.cookieMaxAge
        : undefined
    }
    return await this.$auth.loginWith('local', {
      data: login,
    })
  }

  async activateUser(activationCode: string): Promise<DisplayUserApi> {
    const { data } = await this.$axios.post(
      `/site/activations/${activationCode}/activate`
    )
    return data
  }

  async resendActivationCode(username: string): Promise<void> {
    await this.$axios.post(`/site/activations/resend`, {
      username,
    })
  }

  async getProfilePictureUploadUrl(): Promise<string> {
    const upload = await this.$axios.post('/site/users/upload_profile_picture')
    return upload.data.uploadURL
  }

  async deleteProfilePicture(): Promise<void> {
    await this.$axios.post('/site/users/delete_profile_picture')
    await this.fetchUser()
  }

  async createUser(signupInfo: Record<any, any>): Promise<void> {
    return await this.$axios.post(
      `/site/users/${signupInfo.username}`,
      signupInfo
    )
  }

  async patchCurrentUser(userInfo: Record<any, any>): Promise<void> {
    const data = await this.$axios.patch('/site/users', userInfo)
    if (data.data.access_token) {
      await this.$auth.setUserToken(
        data.data.access_token,
        data.data.refresh_token
      )
    }
    await this.$auth.fetchUser()
  }

  async requestPasswordReset(username: string): Promise<void> {
    await this.$axios.post(`/site/users/${username}/requestreset`)
  }

  async resetPassword(resetCode: string, password: string): Promise<void> {
    await this.$axios.post('/site/users/reset', {
      password_reset_code: resetCode,
      password,
    })
  }

  async deleteCurrentUser(): Promise<void> {
    await this.$axios.delete('/site/users')
    deleteSharedAuthCookies()
    window.location.reload()
  }

  async fetchUser(): Promise<void> {
    await this.$auth.fetchUser()
  }

  get preferences(): WebPreferences {
    const user = this.user?.preferences
    const local = this.$store.getters['local/webPreferences'] as WebPreferences
    if (!user) return local

    const prefs = user.t >= local.t ? { ...user } : { ...local }

    // Merge the shown values from both user and local preferences
    if (user.shown && local.shown) {
      prefs.shown = Array.from(new Set([...user.shown, ...local.shown]))
    }
    return prefs
  }

  async updatePreferences(preferences: Partial<WebPreferences>) {
    const prefs = {
      ...this.preferences,
      ...preferences,
      t: new Date().getTime(),
    }
    const allowedKeys = Object.keys(GetDefaultWebPreferences())
    Object.keys(prefs).forEach((key) => {
      if (!allowedKeys.includes(key)) {
        delete (prefs as any)[key]
      }
    })
    await this.$store.dispatch('local/setWebPreferences', prefs)
    if (!this.loggedIn) return
    await this.patchCurrentUser({
      web_preferences: JSON.stringify(prefs),
    })
  }

  async messageShown(messageID: UserMessages) {
    const shown = this.preferences.shown
    await this.updatePreferences({
      shown: shown ? [...new Set([...shown, messageID])] : [messageID],
    })
  }

  canShowMessage(messageID: UserMessages) {
    const shown = this.preferences.shown
    if (!shown) return true
    return !shown.includes(messageID)
  }
}

// Delete shared sermonaudio.com authentication cookies set by API
function deleteSharedAuthCookies() {
  DeleteCookie(ApiAccessCookie)
  DeleteCookie(ApiRefreshCookie)
}
