import { Context } from '@nuxt/types'
import {
  broadcasterShareUrl,
  broadcasterSignupShareUrl,
  broadcasterSignupWithCoupon,
  CollectionUrl,
  createSiteSermonUrl,
  siteBroadcasterUrl,
  siteSeriesUrl,
  siteWebcastUrl,
  webcastShareUrl,
} from '~/assets/ts/utils/urls'
import { GenerateSitemap } from '~/assets/ts/sitemap/generator'

interface Handler {
  redirect: (status: number, location: string) => void
  localePath: (location: string) => string
  match: RegExpMatchArray
  context: Context
}

interface RouteHandlers {
  path: string
  handler: (data: Handler) => void | Promise<void>
}
const wildcard = '[^\\/]+'

// a list of incoming urls and handlers for redirecting them
const catchAll = [
  {
    path: `/sermon/${wildcard}`,
    handler: ({ redirect, localePath, match }) => {
      redirect(301, localePath(createSiteSermonUrl(match[1])))
    },
  },
  {
    path: siteBroadcasterUrl(wildcard, `series/${wildcard}`),
    handler: ({ redirect, localePath, match }) => {
      redirect(301, localePath(siteSeriesUrl(match[3])))
    },
  },
  {
    path: siteBroadcasterUrl(wildcard, 'about'),
    handler: ({ redirect, localePath, match }) => {
      redirect(301, localePath(siteBroadcasterUrl(match[1])))
    },
  },
  {
    path: broadcasterShareUrl(wildcard),
    handler: ({ redirect, localePath, match }) => {
      redirect(301, localePath(siteBroadcasterUrl(match[1])))
    },
  },
  {
    path: webcastShareUrl(wildcard),
    handler: ({ redirect, localePath, match }) => {
      redirect(301, localePath(siteWebcastUrl(match[1])))
    },
  },
  {
    path: broadcasterSignupShareUrl(wildcard),
    handler: ({ redirect, localePath, match }) => {
      redirect(301, localePath(broadcasterSignupWithCoupon(match[1])))
    },
  },
  {
    path: broadcasterSignupShareUrl(wildcard),
    handler: ({ redirect, localePath, match }) => {
      redirect(301, localePath(broadcasterSignupWithCoupon(match[1])))
    },
  },
  {
    path: `/goj/${wildcard}`,
    handler: ({ redirect, localePath, match }) => {
      redirect(301, localePath(CollectionUrl(`goj-${match[1]}`)))
    },
  },
  {
    path: '/sitemap.xml',
    handler: ({ redirect }) => {
      redirect(301, '/sitemap/index.xml')
    },
  },
  {
    path: `/sitemap/${wildcard}.xml`,
    handler: async ({ context, match }) => {
      await GenerateSitemap(match[1].replace('.xml', ''), context)
    },
  },
] as RouteHandlers[]

const wildcardRegex = new RegExp(wildcard, 'g')
export default async function (context: Context) {
  // TODO: This is disabled because for some reason right now /vault always redirects
  // to /vault/ which causes a redirect loop. We need to figure this out eventually.
  // Once we do, switch trailingSlash to false in nuxt.config.js
  // This makes sure we aren't ending with a trailing slash
  // const newPath = RemoveTrailingSlash(route.path)
  // if (newPath !== route.path) {
  //   redirect(301, newPath)
  //   return
  // }

  const { route, redirect, $apiClient, localePath } = context
  for (const r of catchAll) {
    const regex = new RegExp(`^${r.path}`, 'i')
    if (regex.test(route.path)) {
      const match = route.path.match(wildcardRegex)
      if (match) {
        await r.handler({ redirect, localePath, match, context })
        return
      }
    }
  }
  // not a valid route
  if (route.name === null) {
    const url = `https://www.sermonaudio.com${route.fullPath}`
    const map = await $apiClient.mapPath(url)
    if (map) {
      redirect(map.status, map.internal ? localePath(map.url) : map.url)
    }
  }
}
