import { Context } from '@nuxt/types'
import {
  GenerateDates,
  GetMapByDate,
  MapRoutes,
  ReplaceParam,
  SaFirstMonth,
  SaFirstYear,
  SitemapType,
} from '~/assets/ts/sitemap/utils'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { RenderSitemap, SitemapUrl } from '~/assets/ts/sitemap/xml'
import { apiTimestampToJsDate, SaFirstPreachYear } from '~/assets/ts/utils/date'

export function GetSermonIndexes(locales: string[]): string[] {
  return GetMapByDate({
    sitemap: SitemapType.Sermons,
    locales,
    startYear: SaFirstYear,
    startMonth: SaFirstMonth,
  })
}

// https://www.sermonaudio.com/sermons/date/1965/3
export function DateSermonsSitemap(ctx: Context, locale: string) {
  const sitemap = SitemapType.SermonsByDate
  const priority = 0.5
  const routes = GetRoutes(ctx, locale, sitemap)
  const list = [] as SitemapUrl[]

  const years = GenerateDates(SaFirstPreachYear)
  years.forEach(([year]) => {
    list.push(
      ...routes.flatMap(({ path }) => {
        let url = ReplaceParam(path, year, 'year')
        url = ReplaceParam(url, '', 'month')
        url = ReplaceParam(url, '', 'day')
        return {
          url,
          priority,
          changeFrequency: 'weekly',
        }
      })
    )
  })

  const months = GenerateDates(SaFirstPreachYear, 1)
  months.forEach(([year, month]) => {
    list.push(
      ...routes.flatMap(({ path }) => {
        let url = ReplaceParam(path, year, 'year')
        url = ReplaceParam(url, month, 'month')
        url = ReplaceParam(url, '', 'day')
        return {
          url,
          priority,
          changeFrequency: 'monthly',
        }
      })
    )
  })

  const days = GenerateDates(SaFirstYear, SaFirstMonth, 1)
  const currentYear = new Date().getFullYear()
  days.forEach(([year, month, day]) => {
    list.push(
      ...routes.flatMap(({ path }) => {
        let url = ReplaceParam(path, year, 'year')
        url = ReplaceParam(url, month, 'month')
        url = ReplaceParam(url, day, 'day')
        const current = year === currentYear
        return {
          url,
          priority: current ? 0.8 : priority,
          changeFrequency: current ? 'daily' : 'monthly',
        }
      })
    )
  })

  return RenderSitemap(ctx, sitemap, list)
}

export async function SermonsSitemap(
  ctx: Context,
  locale: string,
  params: string[]
) {
  const sermons = await ctx.$apiClient.getSitemapSermons({
    year: parseInt(params[0]),
    month: parseInt(params[1]),
  })
  const routes = GetRoutes(ctx, locale, SitemapType.Sermons, 2)
  const list = MapRoutes(routes, sermons, (info, { path, param }) => {
    const [sermonID, timestamp] = info
    let url = ReplaceParam(path, sermonID, param)
    url = ReplaceParam(url, '', 'mediaType')
    return {
      url,
      priority: 1,
      updated: apiTimestampToJsDate(timestamp),
    }
  })
  return RenderSitemap(ctx, SitemapType.Sermons, list)
}
