
import Vue, { PropType } from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import SaLink from '~/components/_general/SaLink.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import { DisplayUrl } from '~/assets/ts/utils/urls'

export type Breadcrumb = DisplayUrl

export const BreadcrumbProps = {
  breadcrumbs: {
    type: Array as PropType<Breadcrumb[]>,
    default: () => [],
  },
}

export default Vue.extend({
  name: 'SiteBreadcrumbs',
  components: { InlineIcon, SaLink, SaIcon },
  props: {
    ...BreadcrumbProps,
    inline: {
      type: Boolean,
    },
    blue: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    back(): Breadcrumb | undefined {
      return this.breadcrumbs.length === 1 ? this.breadcrumbs[0] : undefined
    },
    displayedBreadcrumbs(): Breadcrumb[] {
      return this.back ? [] : this.breadcrumbs
    },
  },
})
