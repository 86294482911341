import { Context } from '@nuxt/types'
import { GetSitemapTypeFromPath, SitemapType } from '~/assets/ts/sitemap/utils'

const hiddenRoutes = [
  '/dashboard',
  '/django',
  '/demo',
  '/video-wall',
  '/rewrite',
  '/embed',
  '/admin',
  '/healthz',
  '/legacy',
]

export interface SitemapRoute {
  path: string
  locale: string
  sitemap: SitemapType
}

export function GetAllRoutes({ app }: Context): SitemapRoute[] {
  const routes = app.router?.getRoutes()
  if (!routes) return []
  return routes
    .map((route) => {
      const { name, path } = route
      if (!name) return undefined
      if (hiddenRoutes.find((r) => path.includes(r))) return undefined
      const sitemap = GetSitemapTypeFromPath(path) ?? SitemapType.Unsupported
      const locale = name.split('___')[1]
      return {
        path,
        locale,
        sitemap,
      } as SitemapRoute
    })
    .filter((r) => !!r)
}

export function LogUnsupportedRoutes(ctx: Context) {
  console.log(
    GetAllRoutes(ctx)
      .filter(
        (route) =>
          route.sitemap === SitemapType.Unsupported && route.locale === 'en'
      )
      .map(({ path }) => path)
  )
}

function countParams(route: SitemapRoute) {
  let count = 0
  for (const char of route.path) {
    if (char === ':') {
      count++
    }
  }
  return count
}

export function GetRoutes(
  ctx: Context,
  locale: string,
  map: SitemapType,
  paramCount = undefined as number | undefined
): SitemapRoute[] {
  const routes = GetAllRoutes(ctx).filter(
    (r) => r.locale === locale && map === r.sitemap
  )
  if (paramCount !== undefined) {
    return routes.filter((r) => countParams(r) === paramCount)
  }
  return routes
}
