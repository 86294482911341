import { Context } from '@nuxt/types'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { createNumberArray } from '~/assets/ts/utils/math'
import { SitemapType } from '~/assets/ts/sitemap/utils'

export async function ScriptureSitemap(ctx: Context, locale: string) {
  const { results } = await ctx.$apiClient.getBibleStructure()
  const routes = GetRoutes(ctx, locale, SitemapType.SermonsByScripture)
  const list = [] as string[]
  results.forEach((book) => {
    const chapterID = ':chapter?'
    const verseID = ':verse?'
    routes.forEach(({ path }) => {
      const bookBase = path.replace(':book', book.osisAbbrev)
      list.push(bookBase.replace(`/${chapterID}/${verseID}`, ''))
      const chapters = createNumberArray(book.chapters, 1, 1)
      chapters.forEach((chapter) => {
        const chapterBase = bookBase.replace(chapterID, chapter.toString())
        list.push(chapterBase.replace(`/${verseID}`, ''))
        const verses = createNumberArray(book.verses[chapter], 1, 1)
        verses.forEach((verse) => {
          list.push(chapterBase.replace(verseID, verse.toString()))
        })
      })
    })
  })
  return RenderSitemap(
    ctx,
    SitemapType.SermonsByScripture,
    list.map((url) => {
      return {
        url,
        changeFrequency: 'weekly',
        priority: 1,
      }
    })
  )
}
