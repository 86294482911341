import { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Marked, MarkedExtension, Renderer, Tokens } from 'marked'
import markedLinkifyIt from 'marked-linkify-it'
import { validUrlFormat } from '~/assets/ts/utils/validation'
import { MarkedPlayerExtension } from '~/assets/ts/markdown/player'
import {
  CustomImageRenderer,
  MarkdownImageTokenizer,
} from '~/assets/ts/markdown/image'
import { ElementStyle } from '~/assets/ts/types/misc'

export const MarkedExtensions = {
  extensions: [MarkdownImageTokenizer, MarkedPlayerExtension],
}

interface PlaintextOptions {
  spaces?: boolean
}

function plaintext(options = {} as PlaintextOptions): Renderer {
  const r = new Renderer()
  const whitespace = options.spaces ? ' ' : '\n'
  r.code = function ({ text }) {
    return whitespace + whitespace + text + whitespace + whitespace
  }
  r.blockquote = function ({ tokens }) {
    return '\t' + this.parser.parse(tokens) + whitespace
  }
  r.html = function ({ text }) {
    return text
  }
  r.heading = function ({ tokens }) {
    return this.parser.parseInline(tokens)
  }
  r.hr = function () {
    return whitespace + whitespace
  }
  r.list = function ({ items }) {
    return items.map((i) => r.listitem(i)).join('')
  }
  r.listitem = function ({ text }) {
    return '\t' + text + whitespace
  }
  r.table = function ({ header, rows }) {
    return whitespace + header + whitespace + rows + whitespace
  }
  r.tablerow = function (content) {
    return content + whitespace
  }
  r.tablecell = function ({ text }) {
    return text + '\t'
  }
  r.strong = function ({ tokens }) {
    return this.parser.parseInline(tokens)
  }
  r.em = function ({ tokens }) {
    return this.parser.parseInline(tokens)
  }
  r.codespan = function ({ text }) {
    return text
  }
  r.br = function () {
    return whitespace + whitespace
  }
  r.del = function ({ tokens }) {
    return this.parser.parseInline(tokens)
  }
  r.link = function ({ tokens }) {
    return this.parser.parseInline(tokens)
  }
  r.image = function ({ text }) {
    return text
  }
  r.text = function ({ text }) {
    return text
  }
  r.paragraph = function ({ tokens }) {
    const text = (tokens as Tokens.Paragraph[]).map(({ text }) => text).join('')
    if (!text) return ''
    return whitespace + text + whitespace
  }
  return r
}

// Used to convert HTML symbols to regular characters like &#39; (')
// List over characters: https://ascii.cl/htmlcodes.htm
// This implementation is taken from https://stackoverflow.com/a/7394814
// not currently needed because only a few items are actually broken
// function parseHtmlEntities(str: string) {
//   return str.replace(/&#([0-9]{1,3});/gi, function (match, numStr) {
//     const num = parseInt(numStr, 10) // read num as normal number
//     return String.fromCharCode(num)
//   })
// }

const brokenPlaintextEntities = {
  amp: '&',
  '#39': "'",
  quot: '"',
  lt: '<',
  gt: '>',
} as Record<string, string>

export function FixPlaintextEntities(text: string) {
  return text.replace(/&(#39|amp|quot|lt|gt);/g, (_match, entity) => {
    return brokenPlaintextEntities[entity]
  })
}

export function stripMarkdown(markdownText: string | undefined): string {
  if (!markdownText) return ''
  const marked = new Marked()
  marked.use({
    renderer: plaintext({ spaces: false }),
    ...MarkedExtensions,
  })
  return FixPlaintextEntities(marked.parse(markdownText) as string)
}

export const MarkdownProps = {
  body: {
    type: String as PropType<TranslateResult>,
    default: '',
  },
  plaintext: {
    type: Boolean,
  },
  smallText: {
    type: Boolean,
  },
  /** Controls whether image captions are shown and also allows for specifying image sizes */
  imageCaptions: {
    type: Boolean,
    default: true,
  },
  stylizeLists: {
    type: Boolean,
    default: true,
  },
  listGaps: {
    type: Boolean,
  },
  links: {
    type: Boolean,
    default: true,
  },
  coloredLinks: {
    type: Boolean,
  },
  players: {
    type: Boolean,
  },
  bold: {
    type: Boolean,
    default: true,
  },
  /** The amount of space used between elements (like p, ol, blockquote, img, etc) */
  spacing: {
    type: String,
    default: '1em',
  },
  lines: {
    type: Number,
    default: 0,
  },
}

export interface MarkdownOptions {
  plaintext: boolean
  links: boolean
  bold: boolean
  /** Controls whether image captions are shown and also allows for specifying image sizes */
  imageCaptions: boolean
  stylizeLists: boolean
  listGaps: boolean
  coloredLinks: boolean
  /** The amount of space used between elements (like p, ol, blockquote, img, etc) */
  spacing: string
  smallText: boolean
  players: boolean
  lines: number
}

export function MarkdownClasses(options: Partial<MarkdownOptions> = {}) {
  return `markdown break-words${options.stylizeLists ? ' list-styles' : ''}${
    options.listGaps ? ' list-gaps' : ''
  }${options.coloredLinks ? ' colored-links' : ''}${
    options.smallText ? ' text-sm' : ''
  }`
}

export function MarkdownStyles(options: Partial<MarkdownOptions> = {}) {
  const styles = {
    '--md-spacing': options.spacing,
  } as ElementStyle
  if (options.lines) {
    styles['-webkit-line-clamp'] = options.lines
  }
  return styles
}

// https://github.com/markedjs/marked
// https://marked.js.org/using_advanced
export function MarkdownInstance(options: Partial<MarkdownOptions> = {}) {
  const marked = new Marked()
  marked.use({
    breaks: true,
    smartLists: true,
    xhtml: true,
    renderer: MarkdownRenderer(options),
  } as MarkedExtension)
  marked.use(MarkedExtensions)
  if (options.links) {
    marked.use(markedLinkifyIt())
  }
  return marked
}

function MarkdownRenderer(options: Partial<MarkdownOptions> = {}) {
  if (options.plaintext) return plaintext()

  const renderer = new Renderer()
  if (options.links) {
    renderer.link = function ({ tokens, href, title }) {
      const text = this.parser.parseInline(tokens)
      if (!href || !validUrlFormat(href)) return text
      title = title ? `title="${title}" ` : ''
      return `<a href="${href}" target="_blank" ${title}>${text}</a>`
    }
  } else {
    renderer.link = function ({ tokens }) {
      const text = this.parser.parseInline(tokens)
      return text ?? ''
    }
  }
  if (!options.bold) {
    renderer.strong = function ({ tokens }) {
      return this.parser.parseInline(tokens)
    }
  }
  if (options.imageCaptions) {
    renderer.image = function (image) {
      return CustomImageRenderer(image)
    }
  }
  return renderer
}
