import { ApiClient } from '~/apiclient/apiclient'
import { SitemapNonRomanWildcard } from '~/assets/ts/sitemap/utils'

export interface SitemapLetterParams {
  nonRomanSortNames?: boolean
  sortNameStartsWithLetter?: string
}

function getParams(letter: string) {
  const nonRoman = letter === SitemapNonRomanWildcard
  return {
    nonRomanSortNames: nonRoman,
    sortNameStartsWithLetter: nonRoman ? undefined : letter,
  } as SitemapLetterParams
}

type SitemapSpeaker = [speakerID: number, timestamp: number]
export async function getSitemapSpeakers(
  this: ApiClient,
  letter: string
): Promise<SitemapSpeaker[]> {
  const { data } = await this.$axios.get('sitemap/speakers', {
    params: getParams(letter),
  })
  return data
}

export interface SitemapDateParams {
  year: number
  month?: number
}

type SitemapArticle = [
  broadcasterID: string,
  articleSlug: string,
  timestamp: number,
]
export async function getSitemapArticles(
  this: ApiClient,
  params: SitemapDateParams
): Promise<SitemapArticle[]> {
  const { data } = await this.$axios.get('sitemap/articles', { params })
  return data
}

type SitemapBroadcaster = [broadcasterID: string, timestamp: number]
export async function getSitemapBroadcasters(
  this: ApiClient,
  letter: string
): Promise<SitemapBroadcaster[]> {
  const { data } = await this.$axios.get('sitemap/broadcasters', {
    params: getParams(letter),
  })
  return data
}

type SitemapSeries = [seriesID: string, timestamp: number]
export async function getSitemapSeries(
  this: ApiClient,
  params: SitemapDateParams
): Promise<SitemapSeries[]> {
  const { data } = await this.$axios.get('sitemap/series', { params })
  return data
}

type SitemapSermons = [sermonID: string, timestamp: number]
export async function getSitemapSermons(
  this: ApiClient,
  params: SitemapDateParams
): Promise<SitemapSermons[]> {
  const { data } = await this.$axios.get('sitemap/sermons', { params })
  return data
}

export async function getSitemapCollections(
  this: ApiClient
): Promise<string[]> {
  const { data } = await this.$axios.get('sitemap/collections')
  return data
}
