import { Context } from '@nuxt/types'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { MapRoutes, ReplaceParam, SitemapType } from '~/assets/ts/sitemap/utils'

export async function CategoriesSitemap(ctx: Context, locale: string) {
  const { results } = await ctx.$apiClient.getSermonEventTypes()
  const routes = GetRoutes(ctx, locale, SitemapType.Categories)
  const list = MapRoutes(
    routes,
    results,
    ({ description }, { path, param }) => {
      return {
        url: ReplaceParam(path, description, param),
        priority: 0.6,
        changeFrequency: 'weekly',
      }
    }
  )
  return RenderSitemap(ctx, SitemapType.Categories, list)
}
