
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import DashboardLayoutDefault from '~/components/dashboard/layout/Default.vue'
import {
  dashboardUrl,
  DisplayUrl,
  PageIsDashboard,
} from '~/assets/ts/utils/urls'
import { metadata } from '~/assets/ts/utils/metadata'
import SiteBreadcrumbs from '~/components/site/Breadcrumbs.vue'
import { sendMessageToIframeParent } from '~/assets/ts/utils/iframe'
import DashboardSection from '~/components/dashboard/Section.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SaLinkOrDjango from '~/components/_general/SaLinkOrDjango.vue'

export enum DashboardError {
  Generic = 'dashboard-generic',
  Sermon = 'dashboard-sermon',
  Article = 'dashboard-article',
}

export default Vue.extend({
  name: 'DashboardLayoutError',
  components: {
    SaLinkOrDjango,
    InlineIcon,
    DashboardSection,
    SiteBreadcrumbs,
    DashboardLayoutDefault,
  },
  props: {
    error: {
      type: String as PropType<DashboardError>,
      required: true,
    },
    defaultTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  head(this: any) {
    return metadata(this, { title: this.title })
  },
  computed: {
    componentType(): Record<any, any> {
      return this.django ? DashboardSection : DashboardLayoutDefault
    },
    title(): TranslateResult {
      switch (this.error) {
        case DashboardError.Sermon:
          return this.$t('Sermon Not Found')
        case DashboardError.Article:
          return this.$t('Article Not Found')
        case DashboardError.Generic:
        default:
          return this.defaultTitle
      }
    },
    breadcrumb(): DisplayUrl {
      switch (this.error) {
        case DashboardError.Sermon:
          return {
            url: this.url('sermons'),
            title: this.breadcrumbTitle(this.$t('Sermons')),
          }
        case DashboardError.Article:
          return {
            url: this.url('articles'),
            title: this.breadcrumbTitle(this.$t('Articles')),
          }
        case DashboardError.Generic:
        default:
          return {
            url: this.url(''),
            title: this.breadcrumbTitle(this.$t('Dashboard Overview')),
          }
      }
    },
    text(): TranslateResult {
      return this.$t(
        'Please try searching or verify your URL was entered correctly.'
      )
    },
    django(): boolean {
      return !!PageIsDashboard(this)?.django
    },
  },
  mounted() {
    if (!this.django) return
    sendMessageToIframeParent('title', this.title)
  },
  methods: {
    breadcrumbTitle(page: TranslateResult): TranslateResult {
      return this.$t('Return to {page}', { page })
    },
    url(page: string) {
      return dashboardUrl(page, this.django)
    },
  },
})
