
import Vue, { PropType } from 'vue'
import { Sermon } from '@/models/sermon'
import { HSLA } from '~/assets/ts/utils/color'
import { clamp, isInt } from '~/assets/ts/utils/math'
import PlayerSimpleWaveformBar from '~/components/player/SimpleWaveformBar.vue'
import { wait } from '~/assets/ts/utils/misc'

export interface BarColors {
  wave: string
  scrub: string
  shadow: string
}
export default Vue.extend({
  name: 'PlayerWaveform',
  components: { PlayerSimpleWaveformBar },
  props: {
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    video: {
      type: Boolean,
      required: true,
    },
    playedPercentage: {
      type: Number,
      default: 0,
    },
    bufferedPercentage: {
      type: Number,
      default: 0,
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
    hue: {
      type: Number,
      default: 210,
    },
    saturation: {
      type: Number,
      default: 100,
    },
    dark: {
      type: Boolean,
      required: true,
    },
    white: {
      type: Boolean,
    },
    static: {
      type: Boolean,
    },
    simple: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    allowDefault: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      width: undefined as number | undefined,
      hovered: false,
      scrubbing: false,
      scrubbed: 0,
      mediaTypeChanging: false,
      pixelRatio: 1,
      fetching: false,
    }
  },
  computed: {
    floats(): number[] {
      if (!this.sermon) return []
      return this.$store.getters['sermons/waveform'](this.sermon.id) ?? []
    },
    audioHeight(): number {
      return this.large ? 120 : 64
    },
    videoHeight(): number {
      return 20
    },
    audioBarWidth(): number {
      return 2 / this.pixelRatio
    },
    videoBarWidth(): number {
      return 2 / this.pixelRatio
    },
    audioBarGap(): number {
      return 1 / this.pixelRatio
    },
    videoBarGap(): number {
      return 1 / this.pixelRatio
    },
    collapsedHeight(): number {
      return 5
    },
    height(): number {
      return this.video ? this.videoHeight : this.audioHeight
    },
    barGap(): number {
      return this.video ? this.videoBarGap : this.audioBarGap
    },
    barWidth(): number {
      return this.video ? this.videoBarWidth : this.audioBarWidth
    },
    scrubbedPercentage(): number {
      if (!this.hovered) return 0
      return clamp(this.scrubbed * 100, 0, 100)
    },
    light(): boolean {
      return !this.dark
    },
    container(): Element | undefined {
      if (!this.$refs.wave) return undefined
      return this.$refs.wave as Element
    },
    barPadding(): number {
      if (this.displayCollapsed) return 0
      return this.barGap
    },
    barX(): number {
      return this.barWidth + this.barGap
    },
    waveHeight(): number {
      return this.displayCollapsed ? this.collapsedHeight : this.height
    },
    minPeakHeight(): number {
      return 0.05
    },
    displayCollapsed(): boolean {
      return (this.video || !!this.floats) && this.collapsed
    },
    barColors(): Record<'background' | 'buffered' | 'played', BarColors> {
      const waves = ['background', 'buffered', 'played']
      const colors = {} as Record<string, BarColors>
      for (let i = 0; i < waves.length; i++) {
        const wave = waves[i]
        colors[wave] = {
          wave: this.setBarColor(wave, false),
          shadow: this.setBarColor(wave, true),
          scrub: this.setBarColor(wave, false, true),
        } as BarColors
      }
      return colors
    },
    peaks(): number[] {
      if (!this.width) return []
      const origFloats = [...this.floats]
      const newFloats = []
      const min = [this.minPeakHeight]
      const peakCount = Math.ceil(this.width / this.barX)
      const floatsPerPeak = origFloats.length / (this.width / this.barX)

      let peaksCounted = 0
      for (let i = 0; i < peakCount; i++) {
        const startingIndex = Math.floor(peaksCounted)
        peaksCounted += floatsPerPeak
        const endingIndex = Math.floor(peaksCounted)
        const floats = origFloats.slice(startingIndex, endingIndex)
        const peak = Math.max(...floats, ...min)
        newFloats.push(peak)
      }
      return newFloats
    },
    peaksCount(): number {
      return this.peaks.length
    },
  },
  watch: {
    sermon() {
      if (this.simple) return
      this.getFloats()
    },
    async video() {
      if (this.simple) return
      this.mediaTypeChanging = true
      await wait(300)
      this.mediaTypeChanging = false
    },
    scrubbedPercentage() {
      const value = this.hovered ? this.scrubbedPercentage : undefined
      this.$emit('scrubbing', value)
    },
  },
  async mounted() {
    // Keep the waveform from doing weird sub-pixel stuff
    if (!isInt(window.devicePixelRatio)) {
      this.pixelRatio = window.devicePixelRatio
    }
    if (this.simple) return
    this.setInitialWidth()
    await this.getFloats()
  },
  methods: {
    setInitialWidth() {
      const width = this.$el.clientWidth
      if (width === 0) {
        this.$nextTick(() => {
          this.width = this.$el.clientWidth
        })
      } else {
        this.width = width
      }
    },
    resize({ width }: Record<'width' | 'height', number>) {
      this.width = width
    },
    getScrubPosition(event: MouseEvent) {
      const target = event.target as HTMLElement
      const width = target.clientWidth
      const position = event.offsetX
      return position / width
    },
    exit() {
      this.hovered = false
      this.scrubbing = false
    },
    scrubTo(event: MouseEvent) {
      const scrubPosition = this.getScrubPosition(event)
      const moving = event.type === 'mousemove'
      const click = event.type === 'mouseup'
      if (moving) {
        this.scrubbed = scrubPosition
      }
      if (click) {
        this.scrubbing = false
      }
      if (click || (moving && this.scrubbing)) {
        this.$emit('goToPercentage', scrubPosition)
      }
    },
    async getFloats() {
      if (this.fetching) return
      if (this.floats.length || !this.sermon) {
        this.$emit('rendered')
        return
      }
      this.fetching = true
      await this.$store.dispatch('sermons/fetchWaveform', {
        sermonID: this.sermon?.id,
        allowDefault: this.allowDefault,
        cache: this.allowDefault,
      })
      this.fetching = false
      this.$emit('rendered')
    },
    getBarColor(index: number, shadow = false): string {
      const percentage = (index / this.peaksCount) * 100
      let wave = this.barColors.background as BarColors
      const isPlayed =
        this.playedPercentage && this.playedPercentage > percentage
      const isBuffered =
        this.bufferedPercentage && this.bufferedPercentage > percentage
      const isScrubbed =
        this.scrubbedPercentage && this.scrubbedPercentage > percentage

      if (isPlayed) {
        wave = this.barColors.played
      } else if (isBuffered) {
        wave = this.barColors.buffered
      }

      return shadow ? wave.shadow : isScrubbed ? wave.scrub : wave.wave
    },
    setBarColor(wave: string, shadow = false, scrub = false): string {
      if (this.white) {
        let alpha = 0.8
        if (wave === 'buffered') {
          alpha = 0.1
        }
        if (wave !== 'played') {
          alpha = 0.2
        } else if (shadow) {
          alpha = 0.5
        }
        if (scrub) {
          alpha = 1
        }

        return new HSLA({ hue: this.hue, alpha, saturation: 0, lightness: 100 })
          .css
      }
      let alpha = 1
      let lightness = 50
      let saturation = this.saturation

      if (wave === 'buffered') {
        lightness = this.dark ? 75 : 68
      }
      if (wave !== 'played') {
        saturation = 0
        if (shadow) {
          alpha = 0.2
        } else if (this.video || !!this.floats) {
          alpha = 0.5
        }
      } else if (shadow) {
        lightness = this.dark ? 18 : 80
      }

      if (scrub) {
        lightness += 10
      }

      return new HSLA({ hue: this.hue, alpha, saturation, lightness }).css
    },
    topHeight(bar: number): number {
      if (this.displayCollapsed) return 0
      const value = this.height - this.barHeight(bar)
      if (this.video) return value
      return value * 0.5
    },
    barHeight(bar: number): number {
      return Math.round(bar * this.waveHeight)
    },
  },
})
