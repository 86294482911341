import { Broadcaster } from '@/models/broadcaster'
import {
  CaptionLanguages,
  LanguageCode3,
  Media,
  MediaSet,
} from '@/models/media'
import { Speaker } from '@/models/speaker'
import { Series } from '@/models/series'
import { SermonApi, SermonApiEventType } from '~/apiclient/apisermons'
import {
  boolOrUndefined,
  openPopoutWindow,
  resizableUrl,
} from '~/assets/ts/utils/misc'
import {
  apiDateToJsDate,
  apiTimestampToJsDate,
  apiTimestampToJsTimestamp,
  secondsToHhMmSs,
} from '~/assets/ts/utils/date'
import { HashtagApi } from '~/apiclient/apihashtags'
import { roundToZeros } from '~/assets/ts/utils/math'
import { UserFeedSources } from '~/apiclient/apiusers'
import { LanguageCode2 } from '~/assets/ts/utils/i18n'
import { PublishedStatus } from '~/components/dashboard/PublishedStatus.vue'
import { PlayerMediaType } from '~/assets/ts/enums'

export const DefaultSermonTitle = 'Untitled Sermon'

export function pojoToSermon(
  sermon: SermonApi | Record<string, any> | undefined
) {
  if (!sermon) return undefined
  return new Sermon(sermon)
}

export enum SermonFilter {
  Featured,
  StaffPick,
}

export class Sermon {
  id: string
  bibleText?: string
  broadcaster: Broadcaster
  commentCount: number
  captionLanguages?: CaptionLanguages
  displayTitle: string
  /** Audio only play count */
  downloadCount: number
  eventType: SermonApiEventType
  displayEventType?: string
  favorite?: boolean
  featureDate?: Date
  fullTitle: string
  language: LanguageCode2
  lastAccessDate?: Date
  lastAudioAccessDateTime?: Date
  lastVideoAccessDateTime?: Date
  keywords?: string
  hasAudio: boolean
  hasVideo: boolean
  hasPdf: boolean
  hashtags: HashtagApi[]
  lite: boolean
  mediaSet: MediaSet
  moreInfoText?: string
  preachDate: Date
  publishTimestamp?: number
  series?: Series
  showCC: boolean
  speaker: Speaker
  staffPick: boolean
  subtitle?: string
  videoThumbnail?: string
  videoThumbnailResizable?: string
  waveformPeaksUrl?: string
  videoDownloadCount: number
  audioDurationSeconds?: number
  videoDurationSeconds?: number
  videoPreviewURL?: string
  feedSources?: UserFeedSources[]
  lastPlayTime?: Date

  constructor(sermon: Record<string, any>) {
    this.lite =
      !sermon.type ||
      sermon.type.includes('lite') ||
      sermon.type === 'display_ad_sermon'
    this.id = sermon.sermonID
    this.language = sermon.languageCode
    this.displayTitle = sermon.displayTitle
    this.broadcaster = new Broadcaster(sermon.broadcaster)
    this.fullTitle = sermon.fullTitle
    this.speaker = new Speaker(sermon.speaker)
    this.bibleText = sermon.bibleText ?? undefined
    this.subtitle = sermon.subtitle
    if (sermon.series) {
      this.series = new Series({
        title: this.subtitle,
        broadcaster: sermon.broadcaster,
        ...sermon.series,
      })
    }
    this.lastAccessDate = apiDateToJsDate(sermon.lastAccessDate) as Date
    this.lastAudioAccessDateTime = sermon.lastAudioAccessTimestamp
      ? (apiTimestampToJsDate(sermon.lastAudioAccessTimestamp) as Date)
      : undefined
    this.lastVideoAccessDateTime = sermon.lastVideoAccessTimestamp
      ? (apiTimestampToJsDate(sermon.lastVideoAccessTimestamp) as Date)
      : undefined
    this.showCC = !!sermon.showCC
    this.downloadCount = sermon.downloadCount || 0
    this.videoDownloadCount = sermon.videoDownloadCount || 0
    this.waveformPeaksUrl = sermon.waveformPeaksURL
    this.staffPick = !!sermon.pickDate
    this.featureDate = apiDateToJsDate(sermon.lastFeatureDate)
    this.eventType = sermon.eventType
    this.displayEventType = sermon.displayEventType

    this.moreInfoText = sermon.moreInfoText ? sermon.moreInfoText : undefined
    this.publishTimestamp = sermon.publishTimestamp
      ? apiTimestampToJsTimestamp(sermon.publishTimestamp)
      : undefined

    this.preachDate = apiDateToJsDate(sermon.preachDate) as Date
    this.commentCount = sermon.commentCount

    this.favorite = boolOrUndefined(sermon.followed)
    this.videoThumbnail = sermon.videoThumbnail || undefined
    this.videoThumbnailResizable = sermon.videoThumbnailResizable || undefined
    this.captionLanguages = sermon.captionLanguages

    this.mediaSet = !this.lite
      ? new MediaSet(sermon.media, this.id)
      : new MediaSet(
          {
            text: [],
            video: [],
            audio: [],
          },
          this.id
        )
    if (this.lite) {
      this.hasAudio = sermon.hasAudio
      this.hasVideo = sermon.hasVideo
      this.hasPdf = sermon.hasPDF
    } else {
      this.hasVideo = !!this.media.video.length
      this.hasAudio = !!this.media.audio.length
      this.hasPdf = !!this.media.text.length
    }
    this.keywords = sermon.keywords || undefined
    if (this.keywords) {
      const hashtags = this.keywords.split(' ')
      this.hashtags = hashtags.map((h) => {
        return { hashtag: h } as HashtagApi
      })
    } else {
      this.hashtags = []
    }
    this.videoThumbnail = sermon.videoThumbnail || undefined
    this.videoThumbnailResizable = sermon.videoThumbnailResizable || undefined

    this.audioDurationSeconds = sermon.audioDurationSeconds ?? undefined
    this.videoDurationSeconds = sermon.videoDurationSeconds ?? undefined

    this.videoPreviewURL = sermon.videoPreviewURL
    if (!this.videoPreviewURL && this.hasVideo && !!this.media) {
      this.videoPreviewURL = this.media.videoAdaptive?.streamURL
    }

    this.feedSources = sermon.feedItemSource || undefined
    this.lastPlayTime = sermon.lastAudioAccessTimestamp
      ? apiTimestampToJsDate(sermon.lastAudioAccessTimestamp)
      : undefined
  }

  /** If audio and video durations are within a second of each other, we consider them linked */
  get unlinkedMedia(): boolean {
    const audio = this.media.highestAudio?.duration
    if (!audio) return false
    const video = this.media.highestVideo(true)?.duration
    if (!video) return false
    return Math.abs(audio - video) > 2
  }

  get publishDate(): Date | undefined {
    if (!this.publishTimestamp) return undefined
    return new Date(this.publishTimestamp)
  }

  get eventDisplay(): string {
    return this.displayEventType ?? this.eventType ?? ''
  }

  get media(): MediaSet {
    return this.mediaSet
  }

  get english(): boolean {
    return this.language === 'en'
  }

  get languageCode3(): LanguageCode3 | undefined {
    return this.langCode2ToLangCode3(this.language)
  }

  get publishStatus(): PublishedStatus {
    const now = Date.now()
    if (!this.publishTimestamp) return 'draft'
    if (this.publishTimestamp > now) return 'scheduled'
    return 'published'
  }

  get published(): boolean {
    return this.publishStatus === 'published'
  }

  get publishedOrScheduled(): boolean {
    return this.published || this.scheduled
  }

  get scheduled(): boolean {
    return this.publishStatus === 'scheduled'
  }

  get draft(): boolean {
    return this.publishStatus === 'draft'
  }

  get canPublish(): boolean {
    if (this.published) return false
    return !this.speaker.unknownSpeaker && this.fullTitle !== DefaultSermonTitle
  }

  langCode2ToLangCode3(langCode2: string): LanguageCode3 | undefined {
    if (!this.captionLanguages?.alpha_2.length) return undefined
    const lang2 = this.captionLanguages.alpha_2.indexOf(langCode2)
    if (lang2 < 0) return undefined
    return this.captionLanguages.alpha_3[lang2]
  }

  captionByLangCode2(langCode2: string): Media | undefined {
    const lang3 = this.langCode2ToLangCode3(langCode2)
    if (!lang3) return undefined
    return this.media.caption.find((c) => c.language === lang3)
  }

  thumbnailURL(width: undefined | number = undefined): string {
    if (!this.videoThumbnailResizable || !width) {
      if (this.videoThumbnail) return this.videoThumbnail
      return this.media.highestVideo()?.thumbnailImageURL ?? ''
    }
    const height = Math.round(width * 0.5625)
    return resizableUrl(this.videoThumbnailResizable, width, height) ?? ''
  }

  get shareTitle(): string {
    return `${this.displayTitle} by ${this.speaker.displayName}`
  }

  get durationString(): string {
    return this.duration ? secondsToHhMmSs(this.duration) : ''
  }

  get duration(): number | undefined {
    return this.videoDurationSeconds
      ? this.videoDurationSeconds
      : this.audioDurationSeconds
  }

  get hasMedia(): boolean {
    return this.hasVideo || this.hasAudio
  }

  get totalPlayCount(): number {
    return this.downloadCount + this.videoDownloadCount
  }

  get downloadCountDisplay(): string {
    return this.downloadCount.toLocaleString()
  }

  get videoCountDisplay(): string {
    return this.videoDownloadCount.toLocaleString()
  }

  get playCountDisplay(): string {
    return this.totalPlayCount.toLocaleString()
  }

  get roundedPlayCountDisplay(): string {
    return roundToZeros(this.totalPlayCount, 100)
  }

  get metadata(): string {
    return `${this.speaker.displayName} | ${this.broadcaster.location}`
  }

  get SoloUrl(): string {
    return `${this.broadcaster.SoloUrl}sermons/${this.id}/`
  }

  EmbedUrl(mediaType: PlayerMediaType): string {
    const type = mediaType === PlayerMediaType.Audio ? 'a' : 'v'
    return `embed/classic/player/${type}/${this.id}`
  }

  openPopup(url: string) {
    openPopoutWindow(url)
  }

  chromecastArt(audio: boolean): string {
    return audio
      ? this.broadcaster.albumArt(750)
      : require(`~/assets/images/player/chromecast_video_art.png`)
  }

  matchesSearchTerm(searchTerm: string): boolean {
    const search = searchTerm.toLowerCase()
    if (this.fullTitle.toLowerCase().includes(search)) return true
    if (this.displayTitle.toLowerCase().includes(search)) return true
    if (this.speaker.matchesSearchTerm(search)) return true
    if (this.series) {
      if (this.series.matchesSearchTerm(search)) return true
    } else if (this.broadcaster.matchesSearchTerm(search)) return true
    return false
  }
}
