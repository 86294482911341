import { Context } from '@nuxt/types'
import {
  GetMapByLetter,
  MapRoutes,
  ReplaceParam,
  SitemapType,
} from '~/assets/ts/sitemap/utils'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'

export function GetSpeakerIndexes(locales: string[]): string[] {
  return GetMapByLetter(SitemapType.Speakers, locales, true)
}

export async function SpeakersSitemap(
  ctx: Context,
  locale: string,
  params: string[]
) {
  const speakers = await ctx.$apiClient.getSitemapSpeakers(params[0])
  const routes = GetRoutes(ctx, locale, SitemapType.Speakers)
  const list = MapRoutes(routes, speakers, (speaker, { path, param }) => {
    const [speakerID, updateTimestamp] = speaker
    return {
      url: ReplaceParam(path, speakerID, param),
      priority: 1,
      updated: apiTimestampToJsDate(updateTimestamp),
    }
  })
  return RenderSitemap(ctx, SitemapType.Speakers, list)
}
