import { TranslateResult } from 'vue-i18n'
import Vue from 'vue'
import { Speaker } from '~/models/speaker'
import { Broadcaster } from '~/models/broadcaster'
import { Sermon } from '~/models/sermon'
import { Series } from '~/models/series'
import { ArticleDisplay, PlayerMediaType } from '~/assets/ts/enums'
import { HymnApi, HymnResultsApi } from '~/apiclient/apihymns'
import { RewriteKey, RewriteTypes } from '~/router/rewritetypes'
import { QsPair, qsPairToUrl } from '~/assets/ts/utils/params'
import { AdType, DateParams, DisplayAdApi } from '~/apiclient/apiads'
import {
  DisplayArticleApi,
  NewsCategoryID,
  ReleaseNotesCategoryID,
  SpecialArticleBroadcasterID,
} from '~/apiclient/apiarticles'
import { AnyContext, GetContextRoute } from '~/assets/ts/utils/route'

export function CurrentAbsoluteUrl(vue: Vue) {
  return AbsoluteUrl(vue.$route.fullPath)
}

export function AbsoluteUrl(path = '') {
  return `${process.env.SAWEB_BASE_URL || ''}${path}`
}

export function RemoveTrailingSlash(path: string) {
  if (path !== '/' && path.endsWith('/')) {
    return path.replace(/\/+$/, '') || '/'
  }
  return path
}

export interface DisplayUrl {
  url: string
  title: TranslateResult
}

const dashboardRootUrl = '/dashboard'
const vueDashboardRootUrl = '/dashboard-beta'
export function dashboardUrl(pageString: string, django: boolean) {
  // if we're getting a dashboard url, lets make sure we don't double up on the urls somehow
  pageString = pageString
    .replace(vueDashboardRootUrl, '')
    .replace(dashboardRootUrl, '')
  const root = django ? dashboardRootUrl : vueDashboardRootUrl
  // this shouldn't be necessary, but it's here just to be safe in case somehow we end up with a double slash
  return `${root}/${pageString}`.replace('//', '/')
}

export function PageIsDashboard(context: AnyContext) {
  const path = GetContextRoute(context)?.path || ''
  if (path.includes('/django/')) {
    return {
      django: true,
    }
  }
  if (path.includes(dashboardUrl('', false))) {
    return {
      django: false,
    }
  }
  return undefined
}

export function broadcasterSignupShareUrl(couponCode: string) {
  return `/signup/${couponCode}`
}

export const broadcasterManageRootUrl = '/broadcaster/'
export function broadcasterManageUrl(pageString: string) {
  return `${broadcasterManageRootUrl}${pageString}`
}
export const broadcasterSignInUrl = broadcasterManageUrl('sign-in')
export const broadcasterSignUpUrl = broadcasterManageUrl('sign-up')

export function broadcasterSignupWithCoupon(
  couponCode: string | undefined = undefined
) {
  if (!couponCode) return broadcasterSignUpUrl
  return `${broadcasterSignUpUrl}?coupon=${couponCode}`
}

export function broadcasterSelectUrl(
  context: AnyContext | undefined = undefined
) {
  const select = broadcasterManageUrl('select')
  if (!context) return select
  const route = GetContextRoute(context)
  const next = route?.fullPath.replace('?', '&')
  return next ? `${select}?next=${next}` : select
}

export function siteShareUrl(pageString: string): string {
  return `https://www.sermonaudio.com${pageString}`
}

export const speakerRootUrl = '/speakers/'

export function siteSpeakerUrl(
  speaker: Speaker | number | undefined = undefined
) {
  if (!speaker) return speakerRootUrl
  const id = typeof speaker === 'number' ? speaker : speaker.id
  return `${speakerRootUrl}${id}`
}

export function siteSpeakerSermonsUrl(
  speaker: Speaker | number | undefined,
  options: QsPair[] = []
) {
  return qsPairToUrl(`${siteSpeakerUrl(speaker)}/sermons`, options)
}

export const broadcasterRootUrl = '/broadcasters/'

export function siteBroadcasterUrl(
  broadcaster: Broadcaster | string | undefined = undefined,
  page = ''
) {
  if (!broadcaster) return broadcasterRootUrl
  const name = typeof broadcaster === 'string' ? broadcaster : broadcaster.id
  return `${broadcasterRootUrl}${name}/${page}`
}

export function broadcasterDonateUrl(broadcasterID: string | undefined) {
  return `/give/${broadcasterID}`
}

export function broadcasterContactUrl(broadcasterID: string | undefined) {
  return `/contact/${broadcasterID}`
}

export function broadcasterShareUrl(
  broadcaster: Broadcaster | string | undefined = undefined
) {
  if (!broadcaster) return siteBroadcasterUrl(broadcaster)
  const name = typeof broadcaster === 'string' ? broadcaster : broadcaster.id
  return `/go/${name}`
}

export function redirectDisabledBroadcaster(
  redirect: any,
  route: any,
  broadcaster: Record<string, any>
) {
  const disabled = broadcaster.disabled
  const onDisabled = route.path.includes('/disabled')
  if (disabled === onDisabled) return
  redirect(
    siteBroadcasterUrl(broadcaster.broadcasterID, disabled ? 'disabled' : '')
  )
}

export const sermonRootUrl = '/sermons'

export function siteSermonUrl(
  sermon: Sermon | undefined = undefined,
  type: PlayerMediaType | undefined = undefined
) {
  if (!sermon) return sermonRootUrl
  return createSiteSermonUrl(sermon.id, type)
}

export function siteSermonDateUrl(
  year: string | number | undefined = undefined,
  month: string | number | undefined = undefined,
  day: string | number | undefined = undefined
) {
  if (!year) return `${sermonRootUrl}/date`
  if (!month) return `${sermonRootUrl}/date/${year}/`
  if (!day) return `${sermonRootUrl}/date/${year}/${month}/`
  return `${sermonRootUrl}/date/${year}/${month}/${day}/`
}

export function createSiteSermonUrl(
  sermonID: string,
  type: PlayerMediaType | undefined = undefined
) {
  const str = type === PlayerMediaType.Audio ? '/a' : ''
  return `${sermonRootUrl}/${sermonID}${str}`
}

export const embedRootUrl = '/embed'
export function embedSermonUrl(sermonID: string, type: PlayerMediaType) {
  const letter = type === PlayerMediaType.Audio ? 'a' : 'v'
  return `${embedRootUrl}/classic/player/${letter}/${sermonID}`
}

export const seriesRootUrl = '/series/'

export function siteSeriesUrl(series: Series | string | undefined = undefined) {
  if (!series) return seriesRootUrl
  const id = typeof series === 'string' ? series : series.id
  return `${seriesRootUrl}${id}`
}

export const libraryRootUrl = `/library/`
export function siteLibraryPageUrl(page: string) {
  return `${libraryRootUrl}${page}/`
}
export const libraryBookmarksUrl = siteLibraryPageUrl('bookmarks')
export const libraryBroadcastersUrl = siteLibraryPageUrl(
  'following/broadcasters'
)
export const librarySpeakersUrl = siteLibraryPageUrl('following/speakers')
export const librarySeriesUrl = siteLibraryPageUrl('following/series')
export const userRootUrl = `/user/`

export function siteUserPageUrl(page: string) {
  return `${userRootUrl}${page}/`
}

export function siteLoginUrl(context: AnyContext, signup = false): string {
  const route = GetContextRoute(context)
  const next = route?.fullPath ?? ''
  let path = `?next=${next.replace('?', '&')}`
  if (next.includes('login') || next.includes('logout') || next === '/')
    path = ''
  if (signup) {
    path += `${path ? '&' : '?'}signup=true`
  }
  return `${siteUserPageUrl('login')}${path}`
}

export function siteHymnUrl(
  hymn: HymnApi | HymnResultsApi,
  page = '',
  tuneID = ''
): string {
  const pageString = page ? `/${page}` : ''
  const tuneIdString = tuneID ? `?tuneID=${tuneID}` : ''
  return `/${hymn.psalter ? 'psalter' : 'hymnal'}/${
    hymn.hymnID
  }${tuneIdString}${pageString}`
}

export const foundationsPsalterRootUrl = '/rewrite/psalter/'

export function foundationsHymnUrl(
  context: Record<string, any>,
  hymn: HymnApi | HymnResultsApi,
  page = '',
  tuneID = ''
): string {
  const pageString = page ? `/${page}` : ''
  const tuneIdString = tuneID ? `?tuneID=${tuneID}` : ''
  return customSitePath(
    context,
    'psalter',
    `${foundationsPsalterRootUrl}${hymn.hymnID}${tuneIdString}${pageString}`
  )
}

export const scriptureRootUrl = `${sermonRootUrl}/scripture`

export function siteScriptureUrl(
  book: string | undefined = undefined,
  chapter: string | number | undefined = undefined,
  verse: string | number | undefined = undefined
): string {
  if (!book) return scriptureRootUrl
  book = book.toLowerCase()
  if (!chapter) return `${scriptureRootUrl}/${book}`
  if (!verse) return `${scriptureRootUrl}/${book}/${chapter}`
  return `${scriptureRootUrl}/${book}/${chapter}/${verse}`
}

export enum AdPage {
  Create = 'create',
  Stats = 'stats',
  AllStats = 'stats/all',
  AdStats = 'stats/ad',
  Edit = 'edit',
  Orders = 'orders',
  Help = 'help',
}

export const promotionRootUrl = '/promotions/'
export const AdStatsDateBeginKey = 'begin'
export const AdStatsDateEndKey = 'end'

export function promotionUrl(
  adType?: AdType,
  page?: AdPage,
  id: string | undefined = undefined,
  dates: DateParams | undefined = undefined
) {
  if (id) {
    adType = undefined
    id = `/${id}`
  } else if (dates) {
    id = `?${AdStatsDateBeginKey}=${dates.dateBegin}&${AdStatsDateEndKey}=${dates.dateEnd}`
  }
  return `${promotionRootUrl}${page ?? ''}${adType ? `/${adType}` : ''}${
    id ?? ''
  }`
}

export function promoteSermonUrl(adType: AdType, sermonID: string) {
  return `${promotionUrl(adType, AdPage.Create)}?sermonID=${sermonID}`
}

export function routeMatch(
  context: Record<string, any>,
  routes: string[] | undefined,
  exact = false
) {
  let additional = false
  if (routes) {
    const path = context.$route.fullPath
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i]
      // a fuzzy match option: path.includes(route)
      // this is less forgiving thus a more accurate match
      if ((!exact && path.indexOf(route) === 0) || (exact && path === route)) {
        additional = true
      }
    }
  }

  return additional
}

export function customSitePath(
  context: Record<string, any>,
  config: RewriteKey,
  path: string
) {
  const root = RewriteTypes[config].path
  const rewritten = !context.$route.path.includes(root)
  return rewritten ? path.replace(root, '') : path
}

export const webcastRootUrl = '/webcasts/'

export function siteWebcastUrl(
  broadcaster: Broadcaster | string | undefined = undefined
) {
  if (!broadcaster) return webcastRootUrl
  const name = typeof broadcaster === 'string' ? broadcaster : broadcaster.id
  return `${webcastRootUrl}${name}/`
}

export function webcastShareUrl(
  broadcaster: Broadcaster | string | undefined = undefined
) {
  if (!broadcaster) return webcastRootUrl
  const name = typeof broadcaster === 'string' ? broadcaster : broadcaster.id
  return `/live/${name}`
}

export interface FeatureLink {
  url: string
  isSiteUrl: boolean
}

export function featureLink(ad: DisplayAdApi): FeatureLink {
  let url = ad.url
  if (ad.sermon) {
    const video = ad.renderHint === 'video'
    const mediaType = video ? PlayerMediaType.Video : PlayerMediaType.Audio
    url = `${createSiteSermonUrl(ad.sermon.sermonID, mediaType)}?autoplay=1`
  } else if (ad.seriesID) {
    url = siteSeriesUrl(ad.seriesID)
  } else if (/(web|beta|www)\.sermonaudio\.com/.test(url)) {
    // one day we want this to be smarter and use proper data rather than generic urls
    url = url.replace(/.*(web|beta|www)\.sermonaudio\.com/, '')
    url = RemoveTrailingSlash(url)
  }
  return {
    url,
    isSiteUrl: ad.url !== url,
  }
}

export function AdTrackerUrl(campaignID: string | null, view = true) {
  if (!campaignID) return ''
  const time = new Date().getTime()
  const host = 'https://samedia-b2-east.b-cdn.net' // http://localhost:8000
  return `${host}/com-sermonaudio-images2/stats.gif?campaign_id=${campaignID}&act=${
    view ? 'view' : 'click'
  }&channel=web&t=${time}`
}

export const myChurchUrl = '/mychurch'

function GetArticleUrlFormat(display: ArticleDisplay) {
  switch (display) {
    case ArticleDisplay.Dashboard:
      return dashboardUrl('articles/{articleID}', false)
    case ArticleDisplay.DjangoDashboard:
      return dashboardUrl('articles/{articleID}', true)
    case ArticleDisplay.News:
      return '/news/{slug}'
    case ArticleDisplay.ReleaseNotes:
      return '/release-notes/{slug}'
    case ArticleDisplay.Standard:
    default:
      return '/article/{broadcasterID}/{slug}'
  }
}

export function GetSpecialArticleUrl(
  categoryID: number | null | undefined,
  article: DisplayArticleApi
) {
  let display = ArticleDisplay.Standard
  if (article.broadcaster.broadcasterID === SpecialArticleBroadcasterID) {
    if (categoryID === NewsCategoryID) {
      display = ArticleDisplay.News
    } else if (categoryID === ReleaseNotesCategoryID) {
      display = ArticleDisplay.ReleaseNotes
    }
  }
  return GetArticleUrl(display, article)
}

export function GetArticleUrl(
  display: ArticleDisplay,
  article: DisplayArticleApi
) {
  let format = GetArticleUrlFormat(display)
  format = format.replace('{slug}', article.slug)
  format = format.replace('{articleID}', article.articleID.toString())
  format = format.replace('{broadcasterID}', article.broadcaster.broadcasterID)
  return format
}

export function HashtagUrl(hashtag: string) {
  return `/hashtag/${hashtag}`
}

export type CollectionDataType = 'sermons' | 'speakers' | 'series' | ''

export function CollectionUrl(
  collectionSlug: string,
  dataType: CollectionDataType = '',
  filterOptions: QsPair[] = []
): string {
  return qsPairToUrl(
    `/collections/${collectionSlug}/${dataType}`,
    filterOptions
  )
}

export function BroadcasterGroupUrl(group: string) {
  return `${broadcasterRootUrl}group/${group}`
}
