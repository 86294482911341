import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { logError } from '~/assets/ts/utils/misc'
import { AreYouSureCheck } from '~/assets/ts/utils/forms'
import { AdType, DisplayAdApi } from '~/apiclient/apiads'
import { GetAdType } from '~/assets/ts/utils/promotions'
import {
  GetServerCookieObj,
  SetCookieObj,
  SitePreferencesCookie,
} from '~/assets/ts/utils/cookies'

export interface SitePreferences {
  showDesktopNav: boolean
  showTranscripts: boolean
}

const SitePreferencesDefault = {
  showDesktopNav: true,
  showTranscripts: false,
} as SitePreferences

export const state = () => ({
  displayAds: [] as DisplayAdApi[],
  /**
   * Indicates there is a dirty form on the current page.
   * This is paired with the AreYouSureForm component and beforeRouteLeave page navigation guard.
   */
  dirtyForm: false,
  /** The timestamp a dirty form was last saved */
  formSaveTime: 0,
  /** The timestamp an autosave was last triggered */
  autoSaveTime: 0,
  /** Used by draggable items on the site to know which item in a list is being dragged */
  draggingIndex: -1,
  modalOpen: false,
  /** This is the current state of the nav, not the user's preference */
  navOpen: false,
  preferences: SitePreferencesDefault,
})

export type SiteState = ReturnType<typeof state>

function NormalizePreferences(
  state: SiteState,
  newPreferences: Partial<SitePreferences>
) {
  const d = SitePreferencesDefault
  const prefs = {
    ...d,
    ...state.preferences,
    ...newPreferences,
  } as SitePreferences

  const keys = Object.keys(d)
  Object.keys(prefs).forEach((key) => {
    if (!keys.includes(key)) {
      delete prefs[key as keyof SitePreferences]
    }
  })
  return prefs
}

export const mutations: MutationTree<SiteState> = {
  ADD_DISPLAY_ADS: (state, displayAds: DisplayAdApi[]) => {
    state.displayAds = displayAds
  },
  SET_DIRTY_FORM: (state, dirty: boolean) => {
    state.dirtyForm = dirty
  },
  SET_FORM_SAVE_TIME: (state, time: number) => {
    state.formSaveTime = time
  },
  SET_AUTO_SAVE_TIME: (state, time: number) => {
    state.autoSaveTime = time
  },
  SET_DRAGGABLE_INDEX: (state, index: number) => {
    state.draggingIndex = index
  },
  SET_MODAL_OPEN: (state, modalOpen: boolean) => {
    state.modalOpen = modalOpen
  },
  SET_NAV_OPEN: (state, navOpen: boolean) => {
    state.navOpen = navOpen
  },
  SET_PREFERENCES: (state, preferences: SitePreferences) => {
    Vue.set(state, 'preferences', preferences)
  },
}

export const getters: GetterTree<SiteState, SiteState> = {
  displayAds: (state) => state.displayAds,
  featuredSermon: (state) => {
    return state.displayAds.find((a) => GetAdType(a) === AdType.FeaturedSermon)
  },
  /**
   * Indicates there is a dirty form on the current page.
   * This is paired with the AreYouSureForm component and beforeRouteLeave page navigation guard.
   */
  dirtyForm: (state) => state.dirtyForm,
  /** The timestamp a dirty form was last saved */
  formSaveTime: (state) => state.formSaveTime,
  autoSaveTime: (state) => state.autoSaveTime,
  draggingIndex: (state) => state.draggingIndex,
  modalOpen: (state) => state.modalOpen,
  navOpen: (state) => state.navOpen,
  preferences: (state) => state.preferences,
}

export const actions: ActionTree<SiteState, SiteState> = {
  formSaved({ commit }) {
    commit('SET_FORM_SAVE_TIME', new Date().getTime())
  },
  autoSaved({ commit }) {
    commit('SET_AUTO_SAVE_TIME', new Date().getTime())
  },
  /**
   * Called from beforeRouteLeave page hook to check if we need to halt navigation.
   */
  areYouSureCheck({ state, commit }, { next }) {
    if (!AreYouSureCheck(state.dirtyForm)) {
      next()
      commit('SET_DIRTY_FORM', false)
    } else {
      next(false)
    }
  },
  async fetchDisplayAds({ commit }) {
    try {
      const ads = await this.$apiClient.getDisplayAds()
      commit('ADD_DISPLAY_ADS', ads)
    } catch (e) {
      logError(e)
    }
  },
  updateSitePreferences(
    { state, commit },
    preferences: Partial<SitePreferences>
  ) {
    const prefs = NormalizePreferences(state, preferences)
    commit('SET_PREFERENCES', prefs)
    SetCookieObj(SitePreferencesCookie, prefs)
  },
  nuxtServerInit({ state, commit }, context) {
    const prefs = NormalizePreferences(
      state,
      GetServerCookieObj(SitePreferencesCookie, SitePreferencesDefault, context)
    )
    commit('SET_PREFERENCES', prefs)
  },
}
