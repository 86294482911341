import { Context } from '@nuxt/types'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { SitemapType } from '~/assets/ts/sitemap/utils'

export function StaticSitemap(ctx: Context, locale: string) {
  const routes = GetRoutes(ctx, locale, SitemapType.Static)
  return RenderSitemap(
    ctx,
    SitemapType.Static,
    routes.map(({ path }) => {
      return {
        url: path,
        priority: 1,
        changeFrequency: 'daily',
      }
    })
  )
}
