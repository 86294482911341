import { Context } from '@nuxt/types'
import { IndexUrl } from '~/assets/ts/sitemap/index'
import { SitemapRoute } from '~/assets/ts/sitemap/routes'
import { Alphabet } from '~/assets/ts/utils/misc'
import { SitemapUrl } from '~/assets/ts/sitemap/xml'

export const SaFirstYear = 2000
export const SaFirstMonth = 1
export const SitemapNonRomanWildcard = '*'

export enum SitemapType {
  Articles = 'articles',
  Broadcasters = 'broadcasters',
  Categories = 'categories',
  Collections = 'collections',
  Hashtags = 'hashtags',
  Hymns = 'hymns',
  Psalms = 'psalms',
  Index = 'index',
  News = 'news',
  ReleaseNotes = 'releasenotes',
  Series = 'series',
  Sermons = 'sermons',
  SermonsByScripture = 'scripturesermons',
  SermonsByDate = 'datesermons',
  Speakers = 'speakers',
  Static = 'static',
  Topics = 'topics',
  Unsupported = 'unsupported', // Debug only
}

const SitemapTypeParams: Record<string, SitemapType> = {
  book: SitemapType.SermonsByScripture,
  broadcasterID: SitemapType.Broadcasters,
  collectionSlug: SitemapType.Collections,
  category: SitemapType.Categories,
  hashtag: SitemapType.Hashtags,
  hymnID: SitemapType.Hymns,
  newsSlug: SitemapType.News,
  psalmID: SitemapType.Psalms,
  releaseNotesSlug: SitemapType.ReleaseNotes,
  seriesID: SitemapType.Series,
  sermonID: SitemapType.Sermons,
  speaker: SitemapType.Speakers,
  speakerID: SitemapType.Speakers,
  topic: SitemapType.Topics,
  year: SitemapType.SermonsByDate,
}

export function GetSitemapTypeFromPath(path: string): SitemapType | undefined {
  if (!path.includes(':')) return SitemapType.Static
  const id = path
    .split('/')
    .find((p) => p.includes(':'))
    ?.replace(/[:?]/g, '') as string

  return SitemapTypeParams[id]
}

export function GetParamFromSitemapType(type: SitemapType): string | undefined {
  return Object.keys(SitemapTypeParams).find(
    (key) => SitemapTypeParams[key] === type
  )
}

export function GenerateDates(
  startYear: number,
  startMonth?: number,
  startDay?: number
) {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const currentDay = currentDate.getDate()

  const result = []
  let year = startYear
  let month = startMonth ?? 1 // Default to January if no month is provided

  if (startDay !== undefined && startMonth !== undefined) {
    let day = startDay
    while (
      year < currentYear ||
      (year === currentYear && month < currentMonth) ||
      (year === currentYear && month === currentMonth && day <= currentDay)
    ) {
      result.push([year, month, day])

      // Move to the next day
      day++
      const daysInMonth = new Date(year, month, 0).getDate()
      if (day > daysInMonth) {
        day = 1
        month++
        if (month > 12) {
          month = 1
          year++
        }
      }
    }
  } else if (startMonth !== undefined) {
    while (
      year < currentYear ||
      (year === currentYear && month <= currentMonth)
    ) {
      result.push([year, month])

      // Move to the next month
      month++
      if (month > 12) {
        month = 1
        year++
      }
    }
  } else {
    while (year <= currentYear) {
      result.push([year])
      year++
    }
  }

  return result
}

export interface MapByDateParams {
  sitemap: SitemapType
  locales: string[]
  startYear: number
  startMonth?: number
}

export function GetMapByDate(params: MapByDateParams): string[] {
  const { sitemap, locales, startMonth, startYear } = params
  return locales.flatMap((locale) => {
    return GenerateDates(startYear, startMonth).map((dates) => {
      return IndexUrl(sitemap, locale, dates)
    })
  })
}

export function GetMapByLetter(
  sitemap: SitemapType,
  locales: string[],
  addWildcard = false
): string[] {
  const letters = Alphabet.split('')
  if (addWildcard) {
    letters.push(SitemapNonRomanWildcard)
  }
  return locales.flatMap((locale) => {
    return letters.map((letter) => {
      return IndexUrl(sitemap, locale, [letter])
    })
  })
}

export function LogSitemap(ctx: Context, map: SitemapType, urls: SitemapUrl[]) {
  console.log(`GENERATING SITEMAP: ${map.toUpperCase()}`)
  const count = urls.length
  console.log(`SITEMAP URL COUNT: ${count.toLocaleString()}`)
  if (count) {
    console.log(`EXAMPLE URL: ${urls[0].url}`)
  }
  if (count > 40000) {
    const error = count > 50000
    const title = error ? 'SITEMAP ERROR' : 'SITEMAP WARNING'
    const message = `${title} "${map}" sitemap is too large`
    ctx.$sentry.captureMessage(message, error ? 'error' : 'warning')
    console.error(message)
  }
}

export function ReplaceId(
  routes: SitemapRoute[],
  replace: string,
  values: string[]
) {
  const list = [] as string[]
  routes.forEach(({ path }) => {
    values.forEach((value) => {
      list.push(path.replace(new RegExp(`:${replace}\\??`), value))
    })
  })
  return list
}

export function ReplaceParam(
  path: string,
  value: string | number,
  param: string = ''
) {
  return path.replace(new RegExp(`:${param}\\??`), value.toString())
}

export interface MapRoutesInfo {
  path: string
  param: string
  index: number
}

export function MapRoutes<T>(
  routes: SitemapRoute[],
  items: T[],
  mapFn: (item: T, fn: MapRoutesInfo) => SitemapUrl
): SitemapUrl[] {
  return routes.flatMap((route) =>
    items.map((item, index) =>
      mapFn(item, {
        path: route.path,
        param: GetParamFromSitemapType(route.sitemap) as string,
        index,
      })
    )
  )
}
