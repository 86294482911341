import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'
import {
  GetSermonOptions,
  SermonApi,
  SermonRequestOptions,
} from '~/apiclient/apisermons'
import { FromSrt, Srt } from '~/assets/ts/utils/srt'
import { Sermon } from '~/models/sermon'

type SrtLanguage = Record<string, Srt[]>

export const state = () => ({
  sermonIds: [] as string[],
  sermons: {} as Record<string, SermonApi>,
  waveforms: {} as Record<string, number[]>,
  sermonList: [] as Record<string, SermonApi>[],
  srts: {} as Record<string, SrtLanguage>,
})

export type SermonsState = ReturnType<typeof state>

export const mutations: MutationTree<SermonsState> = {
  ADD_SERMON: (state, sermon: SermonApi) => {
    Vue.set(state.sermons, sermon.sermonID, sermon)
    if (!state.sermonIds.includes(sermon.sermonID)) {
      state.sermonIds.push(sermon.sermonID)
    }
  },
  ADD_WAVEFORM: (state, { sermonID, waveform }) => {
    Vue.set(state.waveforms, sermonID, waveform)
  },
  ADD_SRTS: (state, { sermonID, languageCode3, srts }) => {
    const sermonSrts = state.srts[sermonID] ?? {}
    sermonSrts[languageCode3] = srts
    Vue.set(state.srts, sermonID, sermonSrts)
  },
  SET_SERMON_LIST: (state, sermons: any) => {
    state.sermonList = sermons
  },
  /** This tells our sermon getter that we don't have any sermons that are retrievable from the cache,
   * but it doesn't break any pages actively rendering the sermons in the cache */
  RESET: (state) => {
    state.sermonIds = []
  },
}

export const getters: GetterTree<SermonsState, SermonsState> = {
  sermon: (state) => (sermonID: string) => state.sermons[sermonID],
  sermonIds: (state) => state.sermonIds,
  waveform: (state) => (sermonID: string) => state.waveforms[sermonID],
  srt: (state) => (sermonID: string, languageCode3: string) => {
    const sermon = state.srts[sermonID]
    if (!sermon) return undefined
    return sermon[languageCode3]
  },
  sermonList: (state) => () => state.sermonList,
}

export interface FetchSermonOptions extends GetSermonOptions {
  sermonID: string
  includeWaveform?: boolean
}

export const actions: ActionTree<SermonsState, SermonsState> = {
  async fetchFilteredSermonList(
    { commit, getters },
    options: Partial<SermonRequestOptions | any>
  ) {
    try {
      const { results } = await this.$apiClient.getFilteredSermonList({
        ...options,
        eventType: options.event,
        broadcasterID: options.broadcaster,
        speakerName: options.speaker,
        book: options.scripture,
        page: options.page,
        pageSize: options.page_size,
        sortBy: options.sort,
        series: options.series,
      })
      if (results.length) {
        if (options.includeWaveform) {
          const sermonID = results[0].sermonID
          if (!getters.waveform(sermonID)) {
            const { waveform } = await this.$apiClient.getWaveform(sermonID)
            commit('ADD_WAVEFORM', {
              waveform,
              sermonID,
            })
          }
        }
        commit('SET_SERMON_LIST', results)
      }
    } catch (e) {
      logError(e)
    }
  },
  async fetchSermon(
    { commit, getters, dispatch },
    options: FetchSermonOptions
  ) {
    try {
      const sermonID = options.sermonID
      const promises = [] as Promise<any>[]

      if (!getters.sermonIds.includes(sermonID)) {
        promises.push(
          this.$apiClient.getSermon(sermonID, options).then((sermon) => {
            commit('ADD_SERMON', sermon)
          })
        )
      }
      if (options.includeWaveform) {
        promises.push(
          dispatch('fetchWaveform', {
            sermonID,
          })
        )
      }
      await Promise.all(promises)
    } catch (e) {
      logError(e)
    }
  },

  async fetchWaveform(
    { commit, getters },
    { sermonID, cache = true, allowDefault = true }
  ) {
    try {
      if (!cache || !getters.waveform(sermonID)) {
        const { waveform, isDefault } = await this.$apiClient.getWaveform(
          sermonID,
          cache
        )
        if (allowDefault || !isDefault) {
          commit('ADD_WAVEFORM', {
            waveform,
            sermonID,
          })
        }
      }
    } catch (e) {
      logError(e)
    }
  },

  async fetchSrt({ commit, getters }, { sermonID, languageCode3 }) {
    if (getters.srt(sermonID, languageCode3)) return
    const sermonPojo = getters.sermon(sermonID) as SermonApi | undefined
    if (!sermonPojo) return

    const sermon = new Sermon(sermonPojo)
    const url = sermon.media?.getTranscript(languageCode3)?.downloadURL
    if (!url) return

    try {
      await this.$axios.get(url).then(({ data }) => {
        commit('ADD_SRTS', {
          sermonID,
          languageCode3,
          srts: FromSrt(data),
        })
      })
    } catch (e) {
      logError(e)
    }
  },
}
