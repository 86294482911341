
import Vue from 'vue'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import { AreYouSureCheck } from '~/assets/ts/utils/forms'

export default Vue.extend({
  name: 'AreYouSureForm',
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      originalFields: JSON.stringify(this.fields),
    }
  },
  computed: {
    dirty(): boolean {
      return this.fieldsJson !== this.originalFields
    },
    fieldsJson(): string {
      return JSON.stringify(this.fields)
    },
    formSaveTime(): number {
      return this.$store.getters['site/formSaveTime']
    },
  },
  watch: {
    dirty() {
      this.$store.commit('site/SET_DIRTY_FORM', this.dirty)
    },
    /** the form was saved */
    formSaveTime() {
      this.setOriginal()
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  async mounted() {
    await waitOneFrame()
    this.setOriginal()
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  methods: {
    setOriginal() {
      this.originalFields = JSON.stringify(this.fields)
    },
    beforeWindowUnload(e: BeforeUnloadEvent) {
      AreYouSureCheck(this.dirty, e)
    },
  },
})
