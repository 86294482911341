import { Context } from '@nuxt/types'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { MapRoutes, ReplaceParam, SitemapType } from '~/assets/ts/sitemap/utils'
import { HashtagRequestOptions } from '~/apiclient/apihashtags'
import { PromiseAll } from '~/assets/ts/utils/misc'

async function getHashtags(
  ctx: Context,
  options: Partial<HashtagRequestOptions>
) {
  const { results } = await ctx.$apiClient.getHashtags({
    ...options,
    size: 1000,
  })
  return results.map(({ hashtag }) => hashtag)
}

export async function HashtagsSitemap(ctx: Context, locale: string) {
  const [trending, recent, sermonCount] = await PromiseAll([
    getHashtags(ctx, { trending: true }),
    getHashtags(ctx, { sortBy: 'recent' }),
    getHashtags(ctx, { sortBy: 'sermon-count' }),
  ])
  let results = [...trending, ...recent, ...sermonCount] as string[]
  results = [...new Set(results)]
  const routes = GetRoutes(ctx, locale, SitemapType.Hashtags)
  const list = MapRoutes(routes, results, (hashtag, { path, param }) => {
    return {
      url: ReplaceParam(path, hashtag, param),
      priority: 0.6,
      changeFrequency: 'weekly',
    }
  })
  return RenderSitemap(ctx, SitemapType.Hashtags, list)
}
