import { DateTimeFormatOptions, TranslateResult } from 'vue-i18n'
import Vue from 'vue'
import { DateFormat } from '~/assets/ts/enums'
import { DatePickerDate } from '~/components/_general/DateInput.vue'
import { DateString } from '~/assets/ts/types/date-string'
import { DateParams } from '~/apiclient/apiads'

export const SaFirstPreachYear = 1950

const Locale = process.env.LOCALE

export function createDate(days: number, hours = 0, minutes = 0, seconds = 0) {
  const d = days * 24 * 60 * 60
  const h = hours * 60 * 60
  const m = minutes * 60
  const date = new Date()
  date.setSeconds(date.getSeconds() + d + h + m + seconds)
  return date
}

export function getApiDate(days: number, hours = 0, minutes = 0, seconds = 0) {
  return Math.round(
    createDate(-days, -hours, -minutes, -seconds).getTime() / 1000
  )
}

export interface HoursMinSec {
  hours: number
  min: number
  sec: number
}

export function secondsToHMS(seconds: number): HoursMinSec {
  seconds = Math.round(seconds)
  const hours = Math.floor(seconds / 3600)
  const min = Math.floor((seconds - hours * 3600) / 60)
  const sec = seconds - hours * 3600 - min * 60
  return {
    hours,
    min,
    sec,
  }
}

export function secondsToHhMmSs(
  seconds: number,
  removeLeadingZeros = false
): string {
  const { hours, min, sec } = secondsToHMS(seconds)
  const secString = sec < 10 ? '0' + sec : sec
  const hourString = hours > 0 ? hours + ':' : ''

  const leading = !removeLeadingZeros || hourString ? '0' : ''
  const minString = min < 10 ? leading + min : min

  // if (hours < 10 && hours > 0) {
  //   hours = leadingZeroes + hours + ':'
  // } else if (hours >= 10) {
  //   hours = hours + ':'
  // } else {
  //   hours = ''
  // }
  return hourString + minString + ':' + secString
}

export function humanizeDuration(vue: Vue, seconds: number) {
  const { hours, min, sec } = secondsToHMS(seconds)
  if (hours > 0) {
    return vue.$t('{h}h {m}m', { h: hours, m: min }).toString()
  } else if (min > 0) {
    return vue.$tc('{n}m', min).toString()
  } else {
    return vue.$tc('{n}s', sec).toString()
  }
}

function TimeAgoSeconds(date: Date, allowSeconds = true, sinceTimestamp = 0) {
  const t = sinceTimestamp || Date.now()
  let timeAgoInSeconds = Math.floor((t - date.getTime()) / 1000)
  if (!allowSeconds && timeAgoInSeconds >= 1) {
    timeAgoInSeconds = Math.max(timeAgoInSeconds, 60)
  }
  return timeAgoInSeconds
}

type epoch = [(s: number) => TranslateResult, number]
function GetEpochs(vue: Vue, short = false): epoch[] {
  return short
    ? [
        [(s) => vue.$tc('{n}yr', s), 31536000],
        [(s) => vue.$tc('{n}mo', s), 2592000],
        [(s) => vue.$tc('{n}d', s), 86400],
        [(s) => vue.$tc('{n}h', s), 3600],
        [(s) => vue.$tc('{n}m', s), 60],
        [(s) => vue.$tc('{n}s', s), 1],
      ]
    : [
        [(s) => vue.$tc('{n} year | {n} years', s), 31536000],
        [(s) => vue.$tc('{n} month | {n} months', s), 2592000],
        [(s) => vue.$tc('{n} day | {n} days', s), 86400],
        [(s) => vue.$tc('{n} hour | {n} hours', s), 3600],
        [(s) => vue.$tc('{n} minute | {n} minutes', s), 60],
        [(s) => vue.$tc('{n} second | {n} seconds', s), 1],
      ]
}

/**
 * Used to get a human-readable time like "1 minute ago".
 *
 * Timestamp is optional. It's primarily used to improve computed prop scenarios, but it also allows us to not generate a new timestamp if we already have one.
 */
export function timesince(
  date: Date,
  vue: Vue,
  allowSeconds = true,
  sinceTimestamp = 0,
  short = false
): string {
  const epochs = GetEpochs(vue, short)
  let timeAgoInSeconds = TimeAgoSeconds(date, allowSeconds, sinceTimestamp)
  if (short && timeAgoInSeconds < 1) {
    timeAgoInSeconds = 1
  }
  for (const [epoch, seconds] of epochs) {
    const interval = Math.floor(timeAgoInSeconds / seconds)
    if (interval >= 1) {
      if (short) return epoch(interval).toString()
      return vue
        .$t('{lengthOfTime} ago', { lengthOfTime: epoch(interval) })
        .toString()
    }
  }

  return vue.$t('just now').toString()
}

export function localizeDatePickerDate(
  value: DatePickerDate,
  format: DateFormat
) {
  if (!value) return ''
  if (value instanceof Date) return localizeDateTime(value as Date, format)
  const dates = value as Date[]
  return localizeDateRange(dates[0], dates[1])
}

export function datePickerStartDate(value: DatePickerDate) {
  if (!value) return undefined
  if (value instanceof Date) return value as Date
  const dates = value as Date[]
  return dates[0]
}

export function datePickerEndDate(value: DatePickerDate) {
  if (!value) return undefined
  if (value instanceof Date) return value as Date
  const dates = value as Date[]
  return dates[1]
}

export function apiDatesToDatePickerDate(
  dateBegin: DateString | undefined,
  dateEnd: DateString | undefined,
  endIsInclusive = false
): DatePickerDate {
  const startDate = apiDateToJsDate(dateBegin)
  let endDate = apiDateToJsDate(dateEnd)
  if (!startDate) return []
  if (!endDate) return [startDate]
  if (endIsInclusive) {
    endDate = addDaysToDate(endDate, -1)
  }
  const dayCount = daysBetweenDates(startDate, endDate)
  return dayCount > 1 ? [startDate, endDate] : [startDate]
}

export function datePickerDateToApiDates(
  dates: DatePickerDate
): DateParams | undefined {
  const startDate = datePickerStartDate(dates)
  const endDate = datePickerEndDate(dates)
  if (!startDate || !endDate) {
    return undefined
  }
  return {
    dateBegin: jsDateToApiDateStr(startDate),
    dateEnd: jsDateToApiDateStr(addDaysToDate(endDate, 1)),
  }
}

export function datePickerDayCount(dates: DatePickerDate) {
  const startDate = datePickerStartDate(dates)
  const endDate = datePickerEndDate(dates)
  if (!startDate) return 0
  if (!endDate || endDate === startDate) return 1
  return daysBetweenDates(startDate, endDate)
}

export function addDaysToDate(date: Date, days: number) {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + Math.round(days))
  return newDate
}

export function localizeDateTime(date: Date, format: DateFormat): string {
  let options = {} as Intl.DateTimeFormatOptions

  switch (format) {
    // 2004
    case DateFormat.Year:
      options = { year: 'numeric' }
      break
    // January
    case DateFormat.Month:
      options = { month: 'long' }
      break
    // January 2004
    case DateFormat.YearMonth:
      options = { year: 'numeric', month: 'long' }
      break
    // 8:15
    case DateFormat.Time:
      options = { hour: 'numeric', minute: 'numeric' }
      break
    case DateFormat.DateWithWeekday:
      options = {
        // year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      }
      break
    // January 12, 2004
    case DateFormat.Date:
      options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      break
    case DateFormat.DateTime:
      options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }
      break
    case DateFormat.ShortDate:
      options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }
      break
    case DateFormat.TinyDate:
      options = {
        month: 'short',
        day: 'numeric',
      }
      break
    case DateFormat.TinyDateTime:
      options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }
      break
    // 4/25/04
    case DateFormat.CompressedDate:
      options = {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
      }
      break
    case DateFormat.DetailedTime:
      options = {
        minute: 'numeric',
        hour: 'numeric',
        second: 'numeric',
        hour12: false,
        // fractionalSecondDigits: 3,
      }
      break
    case DateFormat.MonthDayDate:
      options = {
        month: 'long',
        day: 'numeric',
      }
      break
    case DateFormat.ShortDateTime:
    default:
      options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }
  }
  let d = date.toLocaleString(Locale, options)
  if (format === DateFormat.CompressedDate) {
    d = d.replace(/\//g, '/')
  }
  return d
}

export function localizeDateRange(
  beginDate: Date,
  endDate: Date | undefined = undefined,
  timezone: string | undefined = undefined
) {
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  } as DateTimeFormatOptions
  if (timezone) {
    options.timeZone = timezone
  }
  if (!endDate) {
    return beginDate.toLocaleDateString(Locale, options)
  } else {
    const dateTimeFormat = new Intl.DateTimeFormat(Locale, options)
    return dateTimeFormat.formatRange(beginDate, endDate)
  }
}

export function apiTimestampToJsTimestamp(apiTimestamp: number) {
  return apiTimestamp * 1000
}

export function apiTimestampToJsDate(apiTimestamp: number) {
  return new Date(apiTimestampToJsTimestamp(apiTimestamp))
}

export function daysBetweenDates(date1: Date, date2: Date) {
  const dates = []
  const date = new Date(date1 > date2 ? date2 : date1)
  const endDate = date1 > date2 ? date1 : date2
  // eslint-disable-next-line no-unmodified-loop-condition
  while (date <= endDate) {
    dates.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return dates.length
}

// A class that holds only a Year/Month/Date value
export class UTCDate extends Date {
  constructor(dateStr: string) {
    super(dateStr + 'T00:00:00.000Z')
  }

  // Override Date.toLocaleString() to format date in UTC timeZone
  toLocaleString(locale?: string, options?: Record<string, any>): string {
    return super.toLocaleString(locale ?? Locale, {
      ...options,
      timeZone: 'UTC',
    })
  }
}

export function jsDateToApiDateStr(date: Date): DateString {
  const year = date.getFullYear()
  // Month is zero-based, so add 1 to get the correct month
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  // Concatenate year, month, and day with hyphens
  return `${year}-${month}-${day}` as DateString
}

export function apiDateToJsDate(
  dateString: DateString | undefined
): Date | undefined {
  if (!dateString) return undefined
  // Split the string into parts
  const [yearStr, monthStr, dayStr] = dateString.split('-')

  // Convert strings to numbers
  const year = parseInt(yearStr, 10)
  const month = parseInt(monthStr, 10) - 1 // Months are 0-indexed in JavaScript
  const day = parseInt(dayStr, 10)

  // Validate the parsed values
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    console.error(`Invalid date format: ${dateString}`)
    return undefined
  }

  // Create a new Date object with the parsed values
  const date = new Date(year, month, day)

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error(`Invalid date: ${date}`)
    return undefined
  }

  return date
}

export function apiDateListToJsDateList(dateStrings: DateString[] | undefined) {
  return (dateStrings ?? [])
    .map((d) => apiDateToJsDate(d))
    .filter((d) => d !== undefined) as Date[]
}

export function jsDateToApiTimestamp(jsDate: Date) {
  return Math.round(jsDate.getTime() / 1000)
}

export function localToEst(d: Date): Date {
  // change to utc
  const offset = new Date().getTimezoneOffset()
  d.setMinutes(d.getMinutes() + offset)

  // change to est
  const estOffset = isDst(d) ? -240 : -300
  d.setMinutes(d.getMinutes() + estOffset)
  return new Date(d)
}

export function estToLocal(d: Date): Date {
  // change to utc
  const estOffset = isDst(d) ? -240 : -300
  d.setMinutes(d.getMinutes() - estOffset)

  // set to local
  const offset = new Date().getTimezoneOffset()
  d.setMinutes(d.getMinutes() - offset)
  return new Date(d)
}

export function isDst(d: Date) {
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset()
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== d.getTimezoneOffset()
}

export function dayOfWeek(
  dayOfWeek: number,
  format: 'long' | 'short' | 'narrow' = 'short'
) {
  // Creates a date object for the desired day of the week starting with Sunday
  const date = new Date(2024, 0, dayOfWeek)

  // Use Intl.DateTimeFormat to get the abbreviated day name
  const formatter = new Intl.DateTimeFormat(Locale, {
    weekday: format,
  })

  // Format the date and get the day name
  return formatter.format(date)
}

export function apiTimeToDisplayTime(time: string) {
  const date = new Date(`2000-01-01T${time}`)
  // Assuming your locale is English (United States)
  return date.toLocaleTimeString(Locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
}

// Gets the current UTC day of the year as expressed by a number (up to 365)
// We could make this a timestamp or date string if we want
// but the day of the year should be sufficient
export function GetApiCacheMax() {
  const startOfYear = Date.UTC(new Date().getUTCFullYear(), 0, 0)
  return Math.floor((Date.now() - startOfYear) / 86400000)
}

// This generates a timestamp of X days ago, rounded to Y number of minutes
// We use this primarily for getting newest sermons, while still allowing us
// to cache the timestamp. Without the rounding or UTC,
// the timestamp would be different for everyone
export function GetUTCTimestampDaysAgoRounded(
  daysAgo: number,
  roundToMinutes = 60
) {
  const now = new Date()

  // Calculate the date Y days ago
  now.setUTCDate(now.getUTCDate() - daysAgo)

  // Calculate the number of minutes since midnight
  const minutesSinceMidnight = now.getUTCHours() * 60 + now.getUTCMinutes()

  // Round to the nearest X minutes
  const roundedMinutes =
    Math.floor(minutesSinceMidnight / roundToMinutes) * roundToMinutes

  // Set the rounded hours and minutes
  const hours = Math.floor(roundedMinutes / 60)
  const minutes = roundedMinutes % 60

  now.setUTCHours(hours, minutes, 0, 0)

  // Return the UTC timestamp
  return now.getTime() / 1000
}
