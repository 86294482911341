import { Context } from '@nuxt/types'
import { ArticleApi } from '~/apiclient/apiarticles'
import { CanEditPage } from '~/assets/ts/utils/dashboard'
import { PageIsDashboard } from '~/assets/ts/utils/urls'
import { DashboardError } from '~/components/dashboard/layout/Error.vue'

export default async function (context: Context) {
  const { articleID, broadcasterID, slug } = context.params
  const store = context.store

  await store.dispatch('articles/fetchArticle', {
    articleID,
    broadcasterID,
    slug,
  })
  const article = store.getters['articles/article'] as ArticleApi | undefined
  const dashboard = !!PageIsDashboard(context)
  if (article) {
    if (dashboard) {
      CanEditPage(context, article.broadcaster.broadcasterID)
    }
  } else {
    context.error({
      statusCode: 404,
      message: dashboard ? DashboardError.Article : 'Article not found',
    })
  }
}
