import { Context } from '@nuxt/types'
import { LocaleObject } from '@nuxtjs/i18n'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { GetArticlesIndexes } from '~/assets/ts/sitemap/articles'
import { GetSermonIndexes } from '~/assets/ts/sitemap/sermons'
import { GetSeriesIndexes } from '~/assets/ts/sitemap/series'
import { GetBroadcasterIndexes } from '~/assets/ts/sitemap/broadcasters'
import { GetSpeakerIndexes } from '~/assets/ts/sitemap/speakers'
import { SitemapType } from '~/assets/ts/sitemap/utils'

export function IndexUrl(
  map: SitemapType,
  locale: string,
  params?: (string | number)[]
) {
  params = params ?? []
  const parts = [map, ...params, locale].join('-')
  return `/sitemap/${parts}.xml`
}

function IndexMap(sitemap: SitemapType, locales: string[]) {
  return locales.map((l) => IndexUrl(sitemap, l))
}

export function SitemapIndex(ctx: Context) {
  const locales = ctx.i18n.locales as LocaleObject[]
  const codes = locales.map((l) => l.code)
  const urls = [
    SitemapType.Categories,
    SitemapType.Collections,
    SitemapType.Hashtags,
    SitemapType.Hymns,
    SitemapType.News,
    SitemapType.Psalms,
    SitemapType.ReleaseNotes,
    SitemapType.SermonsByDate,
    SitemapType.SermonsByScripture,
    SitemapType.Static,
    SitemapType.Topics,
  ].flatMap((map) => IndexMap(map, codes))

  urls.push(...GetArticlesIndexes(codes))
  urls.push(...GetSeriesIndexes(codes))
  urls.push(...GetSermonIndexes(codes))
  urls.push(...GetBroadcasterIndexes(codes))
  urls.push(...GetSpeakerIndexes(codes))

  return RenderSitemap(
    ctx,
    SitemapType.Index,
    urls.map((url) => {
      return {
        url,
      }
    })
  )
}
