import { Context } from '@nuxt/types'
import { MapRoutes, ReplaceParam, SitemapType } from '~/assets/ts/sitemap/utils'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import {
  NewsCategoryID,
  ReleaseNotesCategoryID,
  SpecialArticleBroadcasterID,
} from '~/apiclient/apiarticles'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'

export async function NewsSitemap(ctx: Context, locale: string) {
  return await CategorySitemap(ctx, locale, SitemapType.News)
}

export async function ReleaseNotesSitemap(ctx: Context, locale: string) {
  return await CategorySitemap(ctx, locale, SitemapType.ReleaseNotes)
}

async function CategorySitemap(
  ctx: Context,
  locale: string,
  sitemap: SitemapType
) {
  const news = sitemap === SitemapType.News
  const { results } = await ctx.$apiClient.getArticles({
    broadcasterID: SpecialArticleBroadcasterID,
    categoryID: news ? NewsCategoryID : ReleaseNotesCategoryID,
  })
  const routes = GetRoutes(ctx, locale, sitemap)
  const list = MapRoutes(routes, results, (article, { path, param, index }) => {
    const latest = index < 1
    return {
      url: ReplaceParam(path, article.slug, param),
      changeFrequency: latest ? 'daily' : news ? 'weekly' : undefined,
      priority: latest ? 1 : news ? 0.7 : 0.2,
      updated: apiTimestampToJsDate(article.updateTimestamp),
    }
  })
  return RenderSitemap(ctx, sitemap, list)
}
