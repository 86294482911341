// https://www.v9digital.com/insights/sitemap-xml-why-changefreq-priority-are-important/
import { Context } from '@nuxt/types'
import { LogSitemap, SitemapType } from '~/assets/ts/sitemap/utils'
import { RemoveTrailingSlash, siteShareUrl } from '~/assets/ts/utils/urls'

export type SitemapChangeFrequency =
  | 'never'
  | 'yearly'
  | 'monthly'
  | 'weekly'
  | 'daily'
  | 'hourly'
  | 'always'
  | string

export interface SitemapUrl {
  url: string
  priority?: number
  updated?: Date
  changeFrequency?: SitemapChangeFrequency
  isIndex?: boolean
}

function encodeForXml(str: string) {
  return str.replace(/&/g, '&amp;')
}

function formatUrl(url: string): string {
  return siteShareUrl(encodeForXml(RemoveTrailingSlash(url)))
}

const sitemapMinDate = new Date('2025-03-05')

/**
 * If an update date is provided, we specify the day the new sitemap system was
 * introduced as a minimum.
 */
function clampDate(date?: Date) {
  if (date && date < sitemapMinDate) return sitemapMinDate
  return date
}

export function RenderSitemapUrl(obj: SitemapUrl) {
  const type = obj.isIndex ? 'sitemap' : 'url'
  const d = clampDate(obj.updated)
  const p = obj.priority
  const f = obj.changeFrequency
  return [
    `  <${type}>`,
    `    <loc>${formatUrl(obj.url)}</loc>`,
    d ? `    <lastmod>${d.toISOString()}</lastmod>` : '',
    p ? `    <priority>${p}</priority>` : '',
    f ? `    <changefreq>${f}</changefreq>` : '',
    `  </${type}>`,
  ].join('\n')
}

export function RenderSitemap(
  ctx: Context,
  sitemap: SitemapType,
  urls: SitemapUrl[]
): string {
  const isIndex = sitemap === SitemapType.Index
  LogSitemap(ctx, sitemap, urls)
  urls = urls.map((url) => {
    return { ...url, isIndex }
  })
  const render = urls.map((url) => RenderSitemapUrl(url)).join('\n')
  const type = isIndex ? 'sitemapindex' : 'urlset'
  return [
    '<?xml version="1.0" encoding="UTF-8"?>',
    `<${type} xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`,
    render,
    `</${type}>`,
  ].join('\n')
}

function XmlResponse({ res, ssrContext }: Context, xml: string) {
  res.setHeader('Content-Type', 'application/xml')
  res.end(xml)
  if (!ssrContext) return
  ssrContext.redirected = true
}

type SitemapRenderer = (
  ctx: Context,
  locale: string,
  params: string[]
) => Promise<string> | string

export async function RenderXml(
  ctx: Context,
  renderer: SitemapRenderer,
  params: string[]
) {
  const locale = params.pop() as string
  const render = await renderer(ctx, locale, params)
  XmlResponse(ctx, render)
}

type SitemapIndexRenderer = (ctx: Context) => string
export function RenderIndexXml(ctx: Context, renderer: SitemapIndexRenderer) {
  XmlResponse(ctx, renderer(ctx))
}
