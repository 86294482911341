// Converted from https://www.npmjs.com/package/subtitles-parser

const timeMs = function (val: string): number {
  const regex = /(\d+):(\d{2}):(\d{2}),(\d{3})/
  const parts = regex.exec(val) as any

  if (parts === null) {
    return 0
  }

  for (let i = 1; i < 5; i++) {
    parts[i] = parseInt(parts[i], 10)
    if (isNaN(parts[i])) parts[i] = 0
  }

  // hours + minutes + seconds + ms
  return parts[1] * 3600000 + parts[2] * 60000 + parts[3] * 1000 + parts[4]
}

export interface Srt {
  id: string
  startTime: number
  endTime: number
  text: string
}

export function FromSrt(data: string) {
  data = data.replace(/\r/g, '')
  const regex =
    /(\d+)\n(\d{2}:\d{2}:\d{2},\d{3}) --> (\d{2}:\d{2}:\d{2},\d{3})/g
  const dataArray = data.split(regex)
  dataArray.shift()

  const items = [] as Srt[]
  for (let i = 0; i < dataArray.length; i += 4) {
    items.push({
      id: dataArray[i].trim(),
      startTime: timeMs(dataArray[i + 1].trim()) / 1000,
      endTime: timeMs(dataArray[i + 2].trim()) / 1000,
      text: dataArray[i + 3].trim(),
    })
  }

  return items
}
