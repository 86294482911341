import emojiRegex from 'emoji-regex'

export function countWords(str: string) {
  return str.trim().match(/\S+/g)?.length ?? 0
}

export function isEmoji(str: string) {
  return emojiRegex().test(str)
}

export function tooManyCaps(str: string, allowedPercentage = 0.5) {
  const capCount = (str.match(/[A-Z]/g) || []).length
  return capCount / str.length > allowedPercentage
}

export function convertLineBreaks(str: string, convertTo = '<br>'): string {
  return str.replace(/(\r\n|\r|\n)/g, convertTo)
}

export function capitalize(str: string): string {
  str = str.toString()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function camelCaseToSpaces(str: string) {
  const result = str.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function truncateString(
  str: string,
  maxLength = 150,
  preserveWords = false,
  dotCount = 3
) {
  if (str.length <= maxLength) return str
  const truncated = str.substring(0, maxLength - dotCount)
  const dots = '.'.repeat(dotCount)
  if (preserveWords) {
    const lastSpace = truncated.lastIndexOf(' ')
    if (lastSpace > 0) {
      return `${truncated.substring(0, lastSpace)}${dots}`
    }
  }

  return `${truncated}${dots}`
}

export function stringIsNumber(str: string) {
  return !isNaN(parseFloat(str))
}

export function slugify(str: string, delimiter = '-') {
  str = str.replace(/\s+/g, delimiter) // spaces to hyphens
  str = str.replace(/[^\w-]/g, '') // remove non-word characters except hyphens
  str = str.replace(/-+/g, delimiter) // remove consecutive hyphens
  return str.toLowerCase()
}

export function splitOnLastSpace(str: string) {
  const lastSpaceIndex = str.lastIndexOf(' ')
  if (lastSpaceIndex === -1) return [str] // No space found
  return [str.substring(0, lastSpaceIndex), str.substring(lastSpaceIndex + 1)]
}

export function textWasPasted(newString: string, oldString: string) {
  if (newString === oldString) return false

  const newLen = newString.length
  const oldLen = oldString.length

  // Find the longest common prefix
  let prefixLength = 0
  while (
    prefixLength < newLen &&
    prefixLength < oldLen &&
    newString[prefixLength] === oldString[prefixLength]
  ) {
    prefixLength++
  }

  // Find the longest common suffix
  let suffixLength = 0
  while (
    suffixLength < newLen - prefixLength &&
    suffixLength < oldLen - prefixLength &&
    newString[newLen - 1 - suffixLength] ===
      oldString[oldLen - 1 - suffixLength]
  ) {
    suffixLength++
  }

  // Calculate the changed middle section
  const changedNew = newLen - prefixLength - suffixLength
  const changedOld = oldLen - prefixLength - suffixLength

  // If more than one character was changed, it's likely a paste
  return changedNew > 1 || changedOld > 1
}
