import { Context } from '@nuxt/types'
import { MapRoutes, ReplaceParam, SitemapType } from '~/assets/ts/sitemap/utils'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'

export async function HymnsSitemap(ctx: Context, locale: string) {
  return await HymnalSitemap(ctx, locale, SitemapType.Hymns)
}

export async function PsalmsSitemap(ctx: Context, locale: string) {
  return await HymnalSitemap(ctx, locale, SitemapType.Psalms)
}

async function HymnalSitemap(
  ctx: Context,
  locale: string,
  sitemap: SitemapType
) {
  const psalms = sitemap === SitemapType.Psalms
  const { results } = await ctx.$apiClient.getHymns({
    psalterOnly: psalms,
    excludePsalter: !psalms,
  })
  const routes = GetRoutes(ctx, locale, sitemap)
  const list = MapRoutes(routes, results, (hymn, { path, param }) => {
    return {
      url: ReplaceParam(path, hymn.hymnID, param),
      changeFrequency: 'yearly',
      priority: 0.4,
    }
  })
  return RenderSitemap(ctx, sitemap, list)
}
