
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import SiteBreadcrumbs, { Breadcrumb } from '~/components/site/Breadcrumbs.vue'
import SiteLayoutDefault from '~/components/site/layout/Default.vue'
import AreYouSureForm from '~/components/_general/AreYouSureForm.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SaLink from '~/components/_general/SaLink.vue'
import DashboardSection from '~/components/dashboard/Section.vue'

export const DashboardLayoutProps = {
  /** A title used in the page header. This is ignored if django is true */
  title: {
    type: String as PropType<TranslateResult>,
    default: '',
  },
  /** A breadcrumb to use instead of a title. This is ignored if django is true */
  breadcrumb: {
    type: Object as PropType<Breadcrumb>,
    default: undefined,
  },
  django: {
    type: Boolean,
  },
  /**
   * If this prop is passed in, it will use an AreYouSureForm and pass this value in as the fields value.
   *
   * It is used to determine at any given moment if the form has been modified or not. */
  formFields: {
    type: Object,
    default: undefined,
  },
  /**
   * This keeps the submit event from being triggered if the form isn't marked as dirty */
  requireDirty: {
    type: Boolean,
    default: true,
  },
}

export default Vue.extend({
  name: 'DashboardLayoutDefault',
  components: {
    DashboardSection,
    SaLink,
    InlineIcon,
    SaIcon,
    SiteLayoutDefault,
    SiteBreadcrumbs,
  },
  props: {
    ...DashboardLayoutProps,
  },
  computed: {
    AreYouSureForm() {
      return AreYouSureForm
    },
    dirty(): boolean {
      return this.$store.getters['site/dirtyForm']
    },
  },
  methods: {
    submit(event: Event) {
      if (this.requireDirty && !this.dirty) return
      this.$emit('submit', event)
    },
  },
})
