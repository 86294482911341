import { Context } from '@nuxt/types'
import {
  GetMapByDate,
  MapRoutes,
  ReplaceParam,
  SaFirstYear,
  SitemapType,
} from '~/assets/ts/sitemap/utils'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'

export function GetSeriesIndexes(locales: string[]): string[] {
  return GetMapByDate({
    sitemap: SitemapType.Series,
    locales,
    startYear: SaFirstYear,
  })
}

export async function SeriesSitemap(
  ctx: Context,
  locale: string,
  params: string[]
) {
  const series = await ctx.$apiClient.getSitemapSeries({
    year: parseInt(params[0]),
  })
  const routes = GetRoutes(ctx, locale, SitemapType.Series)
  const list = MapRoutes(routes, series, (info, { path, param }) => {
    const [seriesID, timestamp] = info
    return {
      url: ReplaceParam(path, seriesID, param),
      priority: 0.9,
      updated: apiTimestampToJsDate(timestamp),
    }
  })
  return RenderSitemap(ctx, SitemapType.Series, list)
}
