
import Vue, { PropType } from 'vue'
import { NuxtError } from '@nuxt/types'
import { metadata } from '~/assets/ts/utils/metadata'
import SiteLayoutDefault from '~/components/site/layout/Default.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { getStrValuesFromEnum } from '~/assets/ts/enums'
import DashboardLayoutError, {
  DashboardError,
} from '~/components/dashboard/layout/Error.vue'
import { AnyContext } from '~/assets/ts/utils/route'
import { PageIsDashboard } from '~/assets/ts/utils/urls'

export default Vue.extend({
  components: { DashboardLayoutError, SaIcon, SiteLayoutDefault },
  layout(context: AnyContext) {
    const dashboard = PageIsDashboard(context)
    if (dashboard) {
      if (dashboard.django) return 'dashboard-django'
      return 'dashboard'
    }
    return 'site'
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      default() {
        return {
          statusCode: 404,
          message: this.$t('An error occurred'),
        }
      },
    },
  },
  head(this: any) {
    if (this.dashboardError) return {}
    return metadata(this, { title: this.title })
  },
  computed: {
    dashboardError(): DashboardError | undefined {
      const message = this.error.message as string
      if (getStrValuesFromEnum(DashboardError).includes(message)) {
        return message as DashboardError
      }
      return undefined
    },
    title(): string {
      const error = this.error as NuxtError
      return `${error.statusCode} | ${error.message}`
    },
  },
})
