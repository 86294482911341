import { Context } from '@nuxt/types'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { MapRoutes, ReplaceParam, SitemapType } from '~/assets/ts/sitemap/utils'

export async function CollectionsSitemap(ctx: Context, locale: string) {
  const collections = await ctx.$apiClient.getSitemapCollections()
  const routes = GetRoutes(ctx, locale, SitemapType.Collections)
  const list = MapRoutes(routes, collections, (slug, { path, param }) => {
    return {
      url: ReplaceParam(path, slug, param),
      priority: 1,
      changeFrequency: 'weekly',
    }
  })
  return RenderSitemap(ctx, SitemapType.Collections, list)
}
