import { Context } from '@nuxt/types'
import {
  GetMapByDate,
  MapRoutes,
  ReplaceParam,
  SitemapType,
} from '~/assets/ts/sitemap/utils'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'

const startYear = 2024
const startMonth = 8

export function GetArticlesIndexes(locales: string[]): string[] {
  return GetMapByDate({
    sitemap: SitemapType.Articles,
    locales,
    startYear,
    startMonth,
  })
}

export async function ArticlesSitemap(
  ctx: Context,
  locale: string,
  params: string[]
) {
  const articles = await ctx.$apiClient.getSitemapArticles({
    year: parseInt(params[0]),
    month: parseInt(params[1]),
  })
  const routes = GetRoutes(ctx, locale, SitemapType.Broadcasters, 2).filter(
    (route) => !route.path.includes('print')
  )
  const list = MapRoutes(routes, articles, (info, { path, param }) => {
    const [broadcasterID, articleSlug, timestamp] = info
    let url = ReplaceParam(path, broadcasterID, param)
    url = ReplaceParam(url, articleSlug, 'slug')
    return {
      url,
      priority: 1,
      updated: apiTimestampToJsDate(timestamp),
    }
  })
  return RenderSitemap(ctx, SitemapType.Articles, list)
}
