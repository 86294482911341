var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideHeader),expression:"!hideHeader"}],staticClass:"border-b border-theme pb-3",class:[
      _vm.addMargin ? 'mb-5' : '',
      _vm.showSeeAll
        ? 'flex flex-col flex-wrap sm:flex-row sm:items-center sm:justify-between'
        : 'flex-between-center',
    ]},[_vm._t("title",function(){return [_c('div',{class:`font-semibold sm:mr-2 ${
          _vm.enlargeText ? 'text-2xl' : 'text-lg'
        }`},[(_vm.icon)?_c('InlineIcon',{attrs:{"icon":_vm.icon,"flip-side":_vm.flipIcon,"icon-classes":_vm.iconClasses}},[_c('span',[_vm._v("\n            "+_vm._s(_vm.title)+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"text-sm text-gray-metadata"},[_vm._v("\n            "+_vm._s(_vm.subtitle)+"\n          ")])]):_c('div',[_c('span',{staticClass:"flex items-center space-x-3"},[(_vm.numbered)?_c('span',{staticClass:"bg-current w-1.5em h-1.5em shrink-0 rounded-full flex-center"},[_c('span',{staticClass:"text-white dark:text-gray-800"},[_vm._v(_vm._s(_vm.numbered))])]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),_c('span',{staticClass:"text-sm text-gray-metadata"},[_vm._v("\n            "+_vm._s(_vm.subtitle)+"\n          ")])])],1)]}),_vm._v(" "),(_vm.showSeeAll && !_vm.seeAllBottom)?_c('SiteSeeAll',{attrs:{"to":_vm.to,"href":_vm.href,"see-all-text":_vm.seeAllText,"new-tab":_vm.newTab},on:{"click":function($event){return _vm.$emit('click')}}}):_vm._e(),_vm._v(" "),_vm._t("title-right"),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"mt-2 mb-6"},[_vm._v("\n      "+_vm._s(_vm.description)+"\n    ")]):_vm._e()],2),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.seeAllBottom && _vm.showSeeAll)?_c('div',{staticClass:"mt-5 flex justify-end"},[_c('SiteSeeAll',{attrs:{"to":_vm.to,"href":_vm.href,"see-all-text":_vm.seeAllText}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }