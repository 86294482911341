import { Context } from '@nuxt/types'
import {
  GetMapByLetter,
  MapRoutes,
  ReplaceParam,
  SitemapType,
} from '~/assets/ts/sitemap/utils'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'

export function GetBroadcasterIndexes(locales: string[]): string[] {
  return GetMapByLetter(SitemapType.Broadcasters, locales, true)
}

export async function BroadcastersSitemap(
  ctx: Context,
  locale: string,
  params: string[]
) {
  const broadcasters = await ctx.$apiClient.getSitemapBroadcasters(params[0])
  const routes = GetRoutes(ctx, locale, SitemapType.Broadcasters, 1).filter(
    (route) => !route.path.includes('disabled')
  )
  const list = MapRoutes(
    routes,
    broadcasters,
    (broadcaster, { path, param }) => {
      const [broadcasterID, updateTimestamp] = broadcaster
      return {
        url: ReplaceParam(path, broadcasterID, param),
        priority: 1,
        updated: apiTimestampToJsDate(updateTimestamp),
      }
    }
  )
  return RenderSitemap(ctx, SitemapType.Broadcasters, list)
}
