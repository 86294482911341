export function readImage(
  image: File | Blob,
  callback: (dataUrl: string) => void
) {
  const reader = new FileReader()
  reader.onload = (event) => {
    if (event.target) {
      const result = event.target.result as string
      callback(result)
    }
  }
  reader.readAsDataURL(image)
}

export function formatResolution(width: number, height: number): string {
  if (!width || !height) return ''
  return `${Math.round(width)} x ${Math.round(height)}px`
}

interface WebpUrlOptions {
  width?: number | string
  height?: number | string
}

export function webpUrl(url: string, { width, height } = {} as WebpUrlOptions) {
  const sizesInUrl = url.includes('{width}') || url.includes('{height}')
  if (sizesInUrl) {
    if (width) {
      url = url.replace('{width}', width.toString())
    }
    if (height) {
      url = url.replace('{height}', height.toString())
    }
  }

  const urlObj = new URL(url)
  const params = new URLSearchParams(urlObj.search)
  params.set('webp', 'true')

  if (!sizesInUrl) {
    if (width) {
      params.set('width', width.toString())
    }
    if (height) {
      params.set('height', height.toString())
    }
  }

  urlObj.search = params.toString()
  return urlObj.toString()
}
