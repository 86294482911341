import { Context } from '@nuxt/types'
import { RenderSitemap } from '~/assets/ts/sitemap/xml'
import { GetRoutes } from '~/assets/ts/sitemap/routes'
import { ReplaceId, SitemapType } from '~/assets/ts/sitemap/utils'

export async function TopicsSitemap(ctx: Context, locale: string) {
  const { results } = await ctx.$apiClient.getTopics()
  const routes = GetRoutes(ctx, locale, SitemapType.Topics)
  const topics = results.map((t) => encodeURIComponent(t.topicID))
  return RenderSitemap(
    ctx,
    SitemapType.Topics,
    ReplaceId(routes, 'topic', topics).map((url) => {
      return {
        url,
        priority: 1,
        changeFrequency: 'weekly',
      }
    })
  )
}
