
import Vue from 'vue'

export default Vue.extend({
  name: 'DashboardSection',
  props: {
    componentType: {
      type: [Function, String],
      default: 'div',
    },
    componentProps: {
      type: Object,
      default: () => {
        return {}
      },
    },
    django: {
      type: Boolean,
    },
    reducedBottomMargin: {
      type: Boolean,
    },
    noBottomMargin: {
      type: Boolean,
    },
  },
  computed: {
    hasRightColumn(): boolean {
      return !!this.$slots.right?.length
    },
    hasLeftColumn(): boolean {
      return !!this.$slots.left?.length
    },
  },
})
