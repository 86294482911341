import { Context } from '@nuxt/types'
import { SpecialArticleBroadcasterID } from '~/apiclient/apiarticles'

export default async function ({ params, error, store }: Context) {
  await store.dispatch('articles/fetchArticle', {
    broadcasterID: SpecialArticleBroadcasterID,
    slug: params.newsSlug || params.releaseNotesSlug,
  })
  if (!store.getters['articles/article']) {
    error({
      statusCode: 404,
      message: 'News not found',
    })
  }
}
