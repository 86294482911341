import { Context } from '@nuxt/types'
import { RenderIndexXml, RenderXml } from '~/assets/ts/sitemap/xml'
import { SitemapIndex } from '~/assets/ts/sitemap/index'
import { StaticSitemap } from '~/assets/ts/sitemap/static'
import { TopicsSitemap } from '~/assets/ts/sitemap/topics'
import { ScriptureSitemap } from '~/assets/ts/sitemap/scripture'
import { SitemapType } from '~/assets/ts/sitemap/utils'
import { NewsSitemap, ReleaseNotesSitemap } from '~/assets/ts/sitemap/news'
import { HymnsSitemap, PsalmsSitemap } from '~/assets/ts/sitemap/hymnal'
import { DateSermonsSitemap, SermonsSitemap } from '~/assets/ts/sitemap/sermons'
import { SpeakersSitemap } from '~/assets/ts/sitemap/speakers'
import { ArticlesSitemap } from '~/assets/ts/sitemap/articles'
import { SeriesSitemap } from '~/assets/ts/sitemap/series'
import { BroadcastersSitemap } from '~/assets/ts/sitemap/broadcasters'
import { CategoriesSitemap } from '~/assets/ts/sitemap/categories'
import { LogUnsupportedRoutes } from '~/assets/ts/sitemap/routes'
import { HashtagsSitemap } from '~/assets/ts/sitemap/hashtags'
import { CollectionsSitemap } from '~/assets/ts/sitemap/collections'

const debug = false

export async function GenerateSitemap(id: string, ctx: Context) {
  const [label, ...params] = id.split('-')

  if (debug) {
    LogUnsupportedRoutes(ctx)
  }

  // Every sitemap aside from the index must at least have a locale provided
  if (params.length < 1 && label !== SitemapType.Index) {
    return undefined
  }

  switch (label as SitemapType) {
    case SitemapType.Articles:
      await RenderXml(ctx, ArticlesSitemap, params)
      break
    case SitemapType.Broadcasters:
      await RenderXml(ctx, BroadcastersSitemap, params)
      break
    case SitemapType.Categories:
      await RenderXml(ctx, CategoriesSitemap, params)
      break
    case SitemapType.Collections:
      await RenderXml(ctx, CollectionsSitemap, params)
      break
    case SitemapType.Hashtags:
      await RenderXml(ctx, HashtagsSitemap, params)
      break
    case SitemapType.Hymns:
      await RenderXml(ctx, HymnsSitemap, params)
      break
    case SitemapType.Index:
      RenderIndexXml(ctx, SitemapIndex)
      break
    case SitemapType.News:
      await RenderXml(ctx, NewsSitemap, params)
      break
    case SitemapType.Psalms:
      await RenderXml(ctx, PsalmsSitemap, params)
      break
    case SitemapType.ReleaseNotes:
      await RenderXml(ctx, ReleaseNotesSitemap, params)
      break
    case SitemapType.Series:
      await RenderXml(ctx, SeriesSitemap, params)
      break
    case SitemapType.Sermons:
      await RenderXml(ctx, SermonsSitemap, params)
      break
    case SitemapType.SermonsByScripture:
      await RenderXml(ctx, ScriptureSitemap, params)
      break
    case SitemapType.SermonsByDate:
      await RenderXml(ctx, DateSermonsSitemap, params)
      break
    case SitemapType.Speakers:
      await RenderXml(ctx, SpeakersSitemap, params)
      break
    case SitemapType.Static:
      await RenderXml(ctx, StaticSitemap, params)
      break
    case SitemapType.Topics:
      await RenderXml(ctx, TopicsSitemap, params)
      break
  }
}
